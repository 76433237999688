import React, { useState, useEffect, useLayoutEffect } from "react";
import "../../../assets/scss/forms/form.scss";
import { BsSave } from "react-icons/bs";
import { BiTrash } from "react-icons/bi";
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import {
  setCurOrderGRNo,
  setCurOrderId,
} from "../../../store/booking/order/Order";

import { IconContext } from "react-icons";
import { setToggle } from "../../../store/pagination/Pagination";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  MdAddCircleOutline,
  MdRemoveCircleOutline,
  MdAdd,
} from "react-icons/md";
import {
  setAlertType,
  setDataExist,
  setShowAlert,
} from "../../../store/alert/Alert";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import toTitleCase from "../../../titleCase/TitleCase";
import SearchInput from "../../../components/formComponent/searchInput/SearchInput";
import { ServerAddress } from "../../../constants/ServerAddress";
import NSearchInput from "../../../components/formComponent/nsearchInput/NSearchInput";
import Loader from "../../../components/loader/Loader";
import MultiRowSearchInput from "../../../components/formComponent/multiRowSearchInput/MultiRowSearchInput";

import { Button } from "react-bootstrap";
import PageTitle from "../../../components/pageTitle/PageTitle";

const AddClientBooking = () => {
  //Circle Toogle Btn
  const user = useSelector((state) => state.authentication.userdetails);
  console.log("user===", user);

  const home_branch_id = useSelector(
    (state) => state.authentication.userdetails.home_branch
  );
  const search = useSelector((state) => state.searchbar.search_item);
  const accessToken = useSelector((state) => state.authentication.access_token);
  const data_len = useSelector((state) => state.pagination.data_length);
  const [page_num, setpage_num] = useState(1);
  const [order_id, setorder_id] = useState("");
  const [items, setitems] = useState("");
  const [showOrder, setShowOrder] = useState(false);
  const [booking_through, setbooking_through] = useState(false);
  const [ewaybill_no, setewaybill_no] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [actual_weigth, setactual_weigth] = useState("0");

  // Package Tab
  const [current_status, setcurrent_status] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // For Packingmode
  const [packingmode_list, setpackingmode_list] = useState([]);
  const [packingmode_page, setpackingmode_page] = useState(1);
  const [search_packingmode, setsearch_packingmode] = useState("");
  const [packingmode_loaded, setpackingmode_loaded] = useState(false);
  const [packingmode_count, setpackingmode_count] = useState(1);
  const [packingmodeList, setpackingmodeList] = useState();
  const [packingmode_bottom, setpackingmode_bottom] = useState(103);
  const [packingmode_search_item, setpackingmode_search_item] = useState("");
  // const [packingmode_page, setpackingmode_page] = useState(1);
  const [consignor_packingmode, setconsignor_packingmode] = useState("");

  // Invoice Tab
  const [invoice_list, setinvoice_list] = useState([
    {
      ewaybill_no: "",
      invoice_at: "",
      invoice_no: "",
      invoice_amount: "",
      id: "",
    },
  ]);
  const [invoice_ids, setinvoice_ids] = useState([]);
  const [invoice_error, setinvoice_error] = useState(false);
  const [invoice_err_text, setinvoice_err_text] = useState("");

  const DeleteInvoice = (index, data) => {
    if (invoice_list.length > 1) {
      const updatedList = [...invoice_list];
      updatedList.splice(index, 1);
      setinvoice_list(updatedList);
      if (data["id"]) {
        setinvoice_ids((prev) => [...prev, data["id"]]);
      }
    }
  };

  const AddInvoice = () => {
    setinvoice_list([
      ...invoice_list,
      {
        ewaybill_no: "",
        invoice_at: "",
        invoice_no: "",
        invoice_amount: "",
        id: "",
      },
    ]);
  };

  // Private Marka
  const [marka_list, setmarka_list] = useState([
    {
      name: "",
      marking: "",
      id: "",
    },
  ]);
  const [delete_marka_ids, setdelete_marka_ids] = useState([]);
  const DeleteMarka = (index, data) => {
    if (marka_list.length > 1) {
      const updatedList = [...marka_list];
      updatedList.splice(index, 1);
      setmarka_list(updatedList);
      if (data["id"]) {
        setdelete_marka_ids((prev) => [...prev, data["id"]]);
      }
    }
  };

  const AddMarka = () => {
    setmarka_list([
      ...marka_list,
      {
        name: "",
        marking: "",
        id: "",
      },
    ]);
  };

  const [total_packages, settotal_packages] = useState(0);
  const [total_net_weight, settotal_net_weight] = useState(0);
  const [total_ch_weight, settotal_ch_weight] = useState(0);

  let dimension_list1 = [["", "", ""], ["", ""], "", "", ""];
  const [row1, setrow1] = useState([dimension_list1]);
  const [deleted_dimension_ids, setdeleted_dimension_ids] = useState([]);
  const [pkg_error, setpkg_error] = useState(false);
  const [pkg_err_text, setpkg_err_text] = useState("");

  const addPackages = () => {
    dimension_list1 = [["", "", ""], ["", ""], "", "", ""];
    setrow1([...row1, dimension_list1]);
  };

  const [entry_type_btn, setentry_type_btn] = useState("AUTO GENERATE");
  const [isupdating, setisupdating] = useState(false);
  const [gr_no_value, setgr_no_value] = useState("");
  const [gr_no_value_error, setgr_no_value_error] = useState(false);

  const [clicked, setclicked] = useState(false);
  const [consignee_details, setconsignee_details] = useState([]);
  const [consignor_details, setconsignor_details] = useState([]);
  const [type_identity_list, settype_identity_list] = useState([
    "GSTIN",
    "AADHAR",
    "PAN CARD",
  ]);
  const [type_identity_consignor, settype_identity_consignor] = useState(
    type_identity_list[0]
  );
  const [type_identity_consignor_no, settype_identity_consignor_no] =
    useState("");
  const [type_identity_consignee, settype_identity_consignee] = useState(
    type_identity_list[0]
  );
  const [type_identity_consignee_no, settype_identity_consignee_no] =
    useState("");
  const [type_of_booking_list, settype_of_booking_list] = useState([
    "PAID",
    "TO BE BILLED",
    "TOPAY",
    "FOC",
  ]);
  const [type_of_booking, settype_of_booking] = useState(
    type_of_booking_list[1]
  );

  const [type_of_rate, settype_of_rate] = useState("");
  const [type_ptl, settype_ptl] = useState("");
  const [type_ftl, settype_ftl] = useState("");
  const [type_ptlrange, settype_ptlrange] = useState("");

  const [type_ptl_c, settype_ptl_c] = useState("");
  const [type_ftl_c, settype_ftl_c] = useState("");
  const [type_ptlrange_c, settype_ptlrange_c] = useState("");
  const [submit_btn, setsubmit_btn] = useState(false);
  const [order, setorder] = useState([]);
  const [quickorder, setquickorder] = useState([]);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [topay_amount, settopay_amount] = useState(0);

  const [delivery_mode, setdelivery_mode] = useState("");
  const [delivery_type, setdelivery_type] = useState("");
  const [delivery_mode_error, setdelivery_mode_error] = useState(false);
  const [consignor_error, setconsignor_error] = useState(false);
  const [consignee_error, setconsignee_error] = useState(false);

  const [ctype_list] = useState(["CONTRACTUAL"]);
  const [ctype, setctype] = useState(ctype_list[0]);

  const [marktype_list, setmarktype_list] = useState([
    "OWN MARK",
    "PRIVATE MARK",
  ]);
  const [marktype, setmarktype] = useState(marktype_list[1]);

  const [pay_by, setpay_by] = useState("");
  const [pay_by_list, setpay_by_list] = useState([
    "Consignor",
    "Consignee",
    "Third Party",
  ]);

  //Shipper/Consignor Non Registered
  const [consignor_name, setconsignor_name] = useState("");
  const [consignor_address_line1, setconsignor_address_line1] = useState("");
  const [consignor_name_error, setconsignor_name_error] = useState(false);
  const [consignor_list, setconsignor_list] = useState([]);
  const [consignor_id, setconsignor_id] = useState(0);
  const [consignee_id, setconsignee_id] = useState(0);

  // For Shipper/consignor
  const [state_list_s, setstate_list_s] = useState([]);
  const [state, setstate] = useState("");
  const [state_id, setstate_id] = useState("");
  const [pincode_list_s, setpincode_list_s] = useState([]);
  const [pincode_loaded, setpincode_loaded] = useState(false);
  const [pincode, setpincode] = useState("");
  const [pincode_error, setpincode_error] = useState(false);
  const [pincode_error2, setpincode_error2] = useState(false);
  const [by_pincode, setby_pincode] = useState(false);

  const [pincode_page, setpincode_page] = useState(1);
  const [pincode_search_item, setpincode_search_item] = useState("");
  const [pincode_id, setpincode_id] = useState(0);
  const [pincode_list_error, setpincode_list_error] = useState(false);

  const [refresh, setrefresh] = useState(false);
  const [refresh_row, setrefresh_row] = useState(false);
  const [consginee_st, setconsginee_st] = useState("");
  const [consginee_c, setconsginee_c] = useState("");
  const [consignee_p_id, setconsignee_p_id] = useState(0);

  //Shipper/Consignor

  const [items_id, setitems_id] = useState("");
  const [consignor_state, setconsignor_state] = useState("");
  const [consignor_city, setconsignor_city] = useState("");
  const [consignor_pincode, setconsignor_pincode] = useState("");
  const [consignor_add_1, setconsignor_add_1] = useState("");
  const [consignor_add_2, setconsignor_add_2] = useState("");

  const [invoice_no, setinvoice_no] = useState(0);
  const [inv_ewaybill_no, setinv_ewaybill_no] = useState(0);
  const [invoice_amount, setinvoice_amount] = useState(0);
  const [inv_date, setinv_date] = useState("");

  //Walking

  const [consignor_city_error, setconsignor_city_error] = useState(false);
  const [city_list_s, setcity_list_s] = useState([]);
  const [city_id, setcity_id] = useState("");
  const [city, setcity] = useState("");
  const [state_error, setstate_error] = useState(false);
  const [city_error, setcity_error] = useState(false);
  const [pin_code_error, setpin_code_error] = useState(false);
  const [phon_error, setphon_error] = useState(false);

  const [by_pincode_f_c, setby_pincode_f_c] = useState(false);
  const [pincode_list_f_c, setpincode_list_f_c] = useState([]);
  const [pincode_error_f_c, setpincode_error_f_c] = useState(false);
  const [pincode_error2_f_c, setpincode_error2_f_c] = useState(false);
  const [pincode_page_c, setpincode_page_c] = useState(1);
  const [pincode_search_item_c, setpincode_search_item_c] = useState("");
  const [pincode_list_error_c, setpincode_list_error_c] = useState(false);

  // For Item

  const [item, setitem] = useState("");
  const [item_id, setitem_id] = useState("");
  const [item_list, setitem_list] = useState([]);
  const [item_page, setitem_page] = useState(1);
  const [search_item, setsearch_item] = useState("");
  const [item_loaded, setitem_loaded] = useState(false);
  const [item_count, setitem_count] = useState(1);
  const [item_bottom, setitem_bottom] = useState(103);
  const [pkgitem_id, setpkgitem_id] = useState("");

  const [togitem, settogitem] = useState(false);
  const [consignor_page, setconsignor_page] = useState(1);
  const [refresh_c, setrefresh_c] = useState(false);

  // const [packingmode_id, setpackingmode_id] = useState("");
  //Consignee Non Registered
  const [consignee_name, setconsignee_name] = useState("");
  const [consignee_address_line1, setconsignee_address_line1] = useState("");
  const [consignee_address_line2, setconsignee_address_line2] = useState("");
  const [consignee_phone_number, setconsignee_phone_number] = useState("");
  const [consignee_address_line1_error, setconsignee_address_line1_error] =
    useState(false);
  const [consignee_phone_number_error, setconsignee_phone_number_error] =
    useState(false);
  const [by_pincode_c, setby_pincode_c] = useState(false);
  const [city_c, setcity_c] = useState("");
  const [city_id_c, setcity_id_c] = useState("");
  const [state_list_c, setstate_list_c] = useState([]);
  const [pincode_loaded_c, setpincode_loaded_c] = useState(false);
  const [pincode_c, setpincode_c] = useState("");

  const [freightuptocity_list, setfreightuptocity_list] = useState([]);
  const [city_f, setcity_f] = useState("");
  const [city_id_f, setcity_id_f] = useState("");
  const [city_page_f, setcity_page_f] = useState(1);
  const [city_search_item_f, setcity_search_item_f] = useState("");
  const [city_f_loaded, setcity_f_loaded] = useState(false);
  const [city_f_count, setcity_f_count] = useState(1);

  const [consignee_n, setconsignee_n] = useState("");
  const [consignee_address, setconsignee_address] = useState("");

  const [city_page, setcity_page] = useState(1);
  const [city_loaded, setcity_loaded] = useState(false);
  const [city_count, setcity_count] = useState(1);
  const [city_bottom, setcity_bottom] = useState(103);
  const [togcity, settogcity] = useState(false);
  const [togcity_c, settogcity_c] = useState(false);
  const [city_search_item, setcity_search_item] = useState("");
  const [consignordata, setconsignordata] = useState([]);
  const [consignordataidwise, setconsignordataidwise] = useState([]);
  const [state_page, setstate_page] = useState(1);
  const [state_search_item, setstate_search_item] = useState("");
  const [state_id_f_c, setstate_id_f_c] = useState(0);
  const [state_page_c, setstate_page_c] = useState(1);
  const [state_search_item_c, setstate_search_item_c] = useState("");
  const [statec_count, setstatec_count] = useState(1);
  const [statec_loaded, setstatec_loaded] = useState(false);

  //Consignee
  const [consignee_page, setconsignee_page] = useState(1);
  const [consigneedata, setconsigneedata] = useState([]);
  const [consignee_list, setconsignee_list] = useState([]);
  const [consignee_state, setconsignee_state] = useState("");
  const [consignee_city, setconsignee_city] = useState("");
  const [consignee_pincode, setconsignee_pincode] = useState("");

  //origincity
  const [origincity_list, setorigincity_list] = useState([]);
  const [origincity, setorigincity] = useState("");
  const [origincity_id, setorigincity_id] = useState(0);
  const [origincity_page, setorigincity_page] = useState(1);
  const [origincity_search, setorigincity_search] = useState("");
  const [origincity_loaded, setorigincity_loaded] = useState(false);
  const [origincity_count, setorigincity_count] = useState(1);

  //Destination City
  const [destinationcity_list, setdestinationcity_list] = useState([]);
  const [destinationcity, setdestinationcity] = useState("");
  const [destinationcity_id, setdestinationcity_id] = useState(0);
  const [destinationcity_page, setdestinationcity_page] = useState(1);
  const [destinationcity_search, setdestinationcity_search] = useState("");
  const [destinationcity_loaded, setdestinationcity_loaded] = useState(false);
  const [destinationcity_count, setdestinationcity_count] = useState(1);

  const [city_list__c, setcity_list__c] = useState([]);
  const [cityc_loaded, setcityc_loaded] = useState(false);
  const [cityc_count, setcityc_count] = useState(1);
  const [cityc_bottom, setcityc_bottom] = useState(103);

  const [city_error_c, setcity_error_c] = useState(false);
  const [city_page_c, setcity_page_c] = useState(1);
  const [city_search_item_c, setcity_search_item_c] = useState("");

  //Payment Detail
  const [freight_value, setfreight_value] = useState(0);
  const [privatemarka_value_own, setprivatemarka_value_own] = useState(0);
  const [sur_amount, setsur_amount] = useState(0);
  const [doc_charge, setdoc_charge] = useState(0);
  const [gr_charge, setgr_charge] = useState(0);
  const [toll, settoll] = useState(0);
  const [pieces, setpieces] = useState("");
  const [delivery, setdelivery] = useState(0);
  const [pf_amnt, setpf_amnt] = useState(0);
  const [total, settotal] = useState(0);
  const [cartage_fuel, setcartage_fuel] = useState(0);
  const [other_charge, setother_charge] = useState(0);
  const [cgst, setcgst] = useState(0);
  const [sgst, setsgst] = useState(0);
  const [igst, setigst] = useState(0);
  const [total_amount, setTotal_amount] = useState(0);
  let dimension_list = [pieces];

  const [all_consignor_details, setall_consignor_details] = useState([]);
  const [all_consignee_details, setall_consignee_details] = useState([]);
  const [consignor_state_id, setconsignor_state_id] = useState(0);
  const [location_c, setlocation_c] = useState("");
  const [location_id_f_c, setlocation_id_f_c] = useState(0);

  const [location_id_to, setlocation_id_to] = useState("");
  const [location_id, setlocation_id] = useState("");
  const [location_id_f, setlocation_id_f] = useState(0);
  const [to_pin, setto_pin] = useState(false);
  //  step 1
  const [loadc_pincode, setloadc_pincode] = useState(false);
  const [type_of_deliverymode_list, setype_of_deliverymode_list] = useState([
    "DOOR DELIVERY",
    "TRANSPORT DELIVERY",
  ]);

  const [togstate_c, settogstate_c] = useState(false);
  const [togpincode, settogpincode] = useState(false);
  const [pincode_count, setpincode_count] = useState(1);
  const [pincodec_bottom, setpincodec_bottom] = useState(103);
  const [togpincode_c, settogpincode_c] = useState(false);
  const [pincodec_count, setpincodec_count] = useState(1);
  const [load_pincode, setload_pincode] = useState(false);
  const [type_of_deliverymode, settype_of_deliverymode] = useState(
    type_of_deliverymode_list[1]
  );

  const [search_billto_customer, setsearch_billto_customer] = useState("");
  const [billto_customer_page, setbillto_customer_page] = useState(1);
  const [billto_customer_count, setbillto_customer_count] = useState(1);
  const [billto_customer_loaded, setbillto_customer_loaded] = useState(false);
  const [billto_customer_list, setbillto_customer_list] = useState([]);
  const [billto_customer, setbillto_customer] = useState("");
  const [billto_customer_id, setbillto_customer_id] = useState("");

  const [bill_to_all_data, setbill_to_all_data] = useState([]);
  const [togstate, settogstate] = useState(false);
  const [state_loaded, setstate_loaded] = useState(false);
  const [state_count, setstate_count] = useState(1);

  const [pincode_loaded_f_c, setpincode_loaded_f_c] = useState(false);
  const [pincode_bottom, setpincode_bottom] = useState(103);

  const [search_consignee, setsearch_consignee] = useState("");
  const [search_consignor, setsearch_consignor] = useState("");
  const [consignor_count, setconsignor_count] = useState(1);
  const [consignee_count, setconsignee_count] = useState(1);
  const [consignor_bottom, setconsignor_bottom] = useState(103);

  const [consignor_loaded, setconsignor_loaded] = useState(false);
  const [consignee_loaded, setconsignee_loaded] = useState(false);
  const [consignee_bottom, setconsignee_bottom] = useState(103);
  const [consignor_phone_no, setconsignor_phone_no] = useState("");
  const [partyDetails, setPartyDetails] = useState(null);
  console.log("partyDetails===", partyDetails);
  const [partyDetails2, setPartyDetails2] = useState(null);
  const [PartyDetailsIdwise, setPartyDetailsIdwise] = useState(null);
  const [status_toggle, setstatus_toggle] = useState(false);

  // For From Branch
  const [from_branch_list, setfrom_branch_list] = useState([]);
  const [from_branch, setfrom_branch] = useState("");
  const [from_branch_id, setfrom_branch_id] = useState("");
  const [from_branch_error, setfrom_branch_error] = useState(false);
  const [from_branch_page, setfrom_branch_page] = useState(1);
  const [from_branch_loaded, setfrom_branch_loaded] = useState(false);
  const [from_branch_search, setfrom_branch_search] = useState("");
  const [from_branch_count, setfrom_branch_count] = useState(1);
  const [from_branch_bottom, setfrom_branch_bottom] = useState(103);

  // For To Branch
  const [to_branch_list, setto_branch_list] = useState([]);
  const [to_branch, setto_branch] = useState("");
  const [to_branch_id, setto_branch_id] = useState(0);
  const [to_branch_error, setto_branch_error] = useState(false);
  const [to_branch_page, setto_branch_page] = useState(1);
  const [to_branch_loaded, setto_branch_loaded] = useState(false);
  const [to_branch_search, setto_branch_search] = useState("");
  const [to_branch_count, setto_branch_count] = useState(1);
  const [to_branch_bottom, setto_branch_bottom] = useState(103);

  const [quotation_datails, setquotation_datails] = useState([]);
  const [type_of_booking_err, settype_of_booking_err] = useState(false);

  // Quick Booking Ref
  const [quick_page, setquick_page] = useState(1);
  const [client_items_list, setclient_items_list] = useState([]);

  const deleteRow = (index) => {
    const newlist = quotation_datails.slice();
    newlist.splice(index, 1);
    quotation_datails(newlist);
  };

  const [customer_type_error, setcustomer_type_error] = useState(false);
  const [consignor_doc_error, setconsignor_doc_error] = useState(false);
  const [consignor_phone_no_error, setconsignor_phone_no_error] =
    useState(false);
  const [consignor_add_1_error, setconsignor_add_1_error] = useState(false);
  const [
    type_identity_consignee_no_error,
    settype_identity_consignee_no_error,
  ] = useState(false);
  const [Booking_data, setBooking_data] = useState("");

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      // total_quantity: Booking_data.total_quantity || "",
    },

    validationSchema: Yup.object({
      // total_quantity: Yup.string().min(1).required(" Total quantity is required"),uanti
    }),

    onSubmit: (values) => {
      const netWeight = parseFloat(row1[row1.length - 1][2]);
      const chargeWeight = parseFloat(row1[row1.length - 1][3]);
      if (
        entry_type_btn === "MANUALLY" &&
        !isupdating &&
        gr_no_value.length < 6
      ) {
        setgr_no_value_error(true);
        document.getElementById("booking_info").scrollIntoView();
      } else if (ctype === "") {
        setcustomer_type_error(true);
        document.getElementById("booking_info").scrollIntoView();
      } else if (to_branch === "") {
        setto_branch_error(true);
        document.getElementById("booking_info").scrollIntoView();
      } else if (ctype == "CONTRACTUAL" && consignor_name === "") {
        setconsignor_error(true);
        document.getElementById("consinor_info").scrollIntoView();
      } else if (ctype == "CONTRACTUAL" && consignee_name === "") {
        setconsignee_error(true);
        document.getElementById("consignee_info").scrollIntoView();
      } else if (netWeight > chargeWeight) {
        setpkg_error(true);
        setpkg_err_text("Net Weight cannot be greater than Charge Weight");
        document.getElementById("pkg_details").scrollIntoView();
      } else if (row1[row1.length - 1].some((some) => some === "")) {
        setpkg_error(true);
        setpkg_err_text("Package Details are required");
        document.getElementById("pkg_details").scrollIntoView();
      } else if (pkg_error) {
        document.getElementById("pkg_details").scrollIntoView();
      } else if (
        invoice_list[invoice_list.length - 1].invoice_at === "" ||
        invoice_list[invoice_list.length - 1].invoice_no === "" ||
        invoice_list[invoice_list.length - 1].invoice_amount === ""
      ) {
        setinvoice_error(true);
        setinvoice_err_text("Invoice Details are required");
        document.getElementById("inv_details").scrollIntoView();
      } else if (invoice_error) {
        document.getElementById("inv_details").scrollIntoView();
      } else {
        isupdating ? update_order(values) : send_order_data(values);
      }
    },
  });

  useEffect(() => {
    if (type_of_booking == "TOPAY" && isupdating == false) {
      handleShow();
    }
  }, [type_of_booking]);

  useEffect(() => {
    if (gr_no_value !== "") {
      setgr_no_value_error(false);
    }
  }, [gr_no_value]);

  const [other_package_is, setother_package_is] = useState([""]);

  const delivery_list = () => {
    if (location.state === null) {
      settype_of_deliverymode(type_of_deliverymode_list[0]);
    }
  };

  const [circle_btn, setcircle_btn] = useState(true);
  const toggle_circle = () => {
    setcircle_btn(!circle_btn);
  };

  const [circle_btn1, setcircle_btn1] = useState(true);
  const toggle_circle1 = () => {
    setcircle_btn1(!circle_btn1);
  };

  const [circle_btn2, setcircle_btn2] = useState(true);
  const toggle_circle2 = () => {
    setcircle_btn2(!circle_btn2);
  };

  const [circle_btn3, setcircle_btn3] = useState(true);
  const toggle_circle3 = () => {
    setcircle_btn3(!circle_btn3);
  };

  const [circle_btn4, setcircle_btn4] = useState(true);
  const toggle_circle4 = () => {
    setcircle_btn4(!circle_btn4);
  };

  const [circle_btn5, setcircle_btn5] = useState(true);
  const toggle_circle5 = () => {
    setcircle_btn5(!circle_btn5);
  };

  const [circle_btn6, setcircle_btn6] = useState(true);
  const toggle_circle6 = () => {
    setcircle_btn6(!circle_btn6);
  };

  useLayoutEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (state_id) {
      getCities(state_id, "state", "Shipper");
    }
  }, [state_id, city_page, city_search_item]);

  useEffect(() => {
    if (state_id_f_c) {
      getCities(state_id_f_c, "state", "Consignee");
    }
  }, [state_id_f_c, city_page_c, city_search_item_c]);

  useEffect(() => {
    let timeoutId;
    if (city_id !== 0 && city_id) {
      timeoutId = setTimeout(() => {
        getPincode(city_id, "city", "Shipper");
      }, 1);
    }
    return () => clearTimeout(timeoutId);
  }, [city_id, pincode_page, pincode_search_item]);

  useEffect(() => {
    let timeoutId;
    if (city_id_c !== 0 && city_id_c) {
      timeoutId = setTimeout(() => {
        getPincode(city_id_c, "city", "Consignee");
      }, 1);
    }
    return () => clearTimeout(timeoutId);
  }, [city_id_c, pincode_page_c, pincode_search_item_c]);

  const [booking_date, setbooking_date] = useState("");

  useEffect(() => {
    if (location.state === null) {
      let date = new Date();
      let added_date_time =
        String(date.getDate()).length === 1
          ? "0" + String(date.getDate())
          : String(date.getDate());
      let month =
        String(date.getMonth() + 1).length === 1
          ? "0" + String(date.getMonth() + 1)
          : String(date.getMonth() + 1);
      let year = String(date.getFullYear());

      let hour =
        String(date.getHours()).length === 1
          ? "0" + String(date.getHours())
          : String(date.getHours());
      let minutes =
        String(date.getMinutes()).length === 1
          ? "0" + String(date.getMinutes())
          : date.getMinutes();
      setbooking_date(`${year}-${month}-${added_date_time}T${hour}:${minutes}`);
    }
  }, []);

  useEffect(() => {
    if (ctype === "WALKING" && location.state === null) {
      settype_of_booking_list(["PAID", "TOPAY", "FOC"]);
      setpay_by_list(["Consignor", "Consignee"]);
    } else if (ctype === "CONTRACTUAL" && location.state === null) {
      settype_of_booking_list(["PAID", "TO BE BILLED", "TOPAY", "FOC"]);
      setpay_by_list(["Consignor", "Consignee", "Third Party"]);
    }
  }, [ctype]);

  useEffect(() => {
    if (location.state === null) {
      if (type_of_booking === "PAID") {
        setpay_by("Consignor");
      } else if (type_of_booking === "TOPAY") {
        setpay_by("Consignee");
      } else {
        setpay_by("");
      }
    }
  }, [type_of_booking]);


  const [is_found, setis_found] = useState(false)
  // console.log("isfound---",is_found);
  // console.log("row data---",row1[0][0]);

  useEffect(()=>{
    let found = false
    for (let i = 0; i < row1.length; i++) {
      let subArray = row1[i][0]; 
      // console.log("subArray===",subArray);
      if (subArray[2] === "NO") {
       found = true;
        break; 
      }
    }
    setis_found(found)
  },[dimension_list1])


  useEffect(() => {
    if (entry_type_btn === "MANUALLY") {
      setquickorder();
    }
  }, []);

  useLayoutEffect(() => {
    if (state_id !== 0) {
      setcity_page(1);
      setcity_count(1);
      setcity_bottom(103);
      setcity_loaded(true);
    }
  }, [state_id]);

  useLayoutEffect(() => {
    if (state_id_f_c !== 0) {
      setcity_page_c(1);
      setcityc_count(1);
      setcityc_bottom(103);
      setcityc_loaded(true);
    }
  }, [state_id_f_c]);

  useLayoutEffect(() => {
    if (consginee_st !== "") {
      setpincode_loaded_f_c(true);
    }
  }, [consginee_st]);

  useLayoutEffect(() => {
    getStates();
  }, [state_page, state_search_item, refresh]);

  useLayoutEffect(() => {
    if (city_id !== 0) {
      setpincode_page(1);
      setpincode_count(1);
      setpincode_bottom(103);
      setload_pincode(true);
    }
  }, [city_id]);

  useLayoutEffect(() => {
    if (state !== "") {
      setpincode_loaded(true);
    }
  }, [state]);

  const getPincode = async (place_id, filter_by, val) => {
    let pincode_list = [];

    try {
      const resp = await axios.get(
        ServerAddress +
          `master/all_pincode/?search=${""}&p=${
            val === "Shipper" ? pincode_page : pincode_page_c
          }&records=${10}&data=all&pincode_search=${
            val === "Shipper" ? pincode_search_item : pincode_search_item_c
          }` +
          "&place_id=" +
          place_id +
          "&filter_by=" +
          filter_by,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (filter_by !== "pincode") {
        if (val === "Shipper") {
          settogpincode(true);

          if (resp.data.next === null) {
            setload_pincode(false);
          } else {
            setload_pincode(true);
          }
          if (pincode_page === 1) {
            pincode_list = resp.data.results.map((v) => [v.id, v.pincode]);
          } else {
            pincode_list = [
              ...pincode_list_s,
              ...resp.data.results.map((v) => [v.id, v.pincode]),
            ];
          }
          setpincode_count(pincode_count + 2);
          setpincode_list_s(pincode_list);
        } else {
          settogpincode_c(true);
          if (resp.data.next === null) {
            setloadc_pincode(false);
          } else {
            setloadc_pincode(true);
          }
          if (pincode_page_c === 1) {
            pincode_list = resp.data.results.map((v) => [v.id, v.pincode]);
          } else {
            pincode_list = [
              ...pincode_list_f_c,
              ...resp.data.results.map((v) => [v.id, v.pincode]),
            ];
          }
          setpincodec_count(pincodec_count + 2);
          setpincode_list_f_c(pincode_list);
        }
      } else if (resp.data.results.length > 0 && val === "Shipper") {
        console.log("resp===", resp.data.results);
        setconsignor_city(toTitleCase(resp.data.results[0].city_name));
        setcity_id(resp.data.results[0].city);
        setstate(toTitleCase(resp.data.results[0].state_name));
        setstate_id(resp.data.results[0].state_id);
        setpincode(resp.data.results[0].pincode);
        setpincode_id(resp.data.results[0].id);
      } else if (resp.data.results.length > 0 && val === "Consignee") {
        setconsginee_c(toTitleCase(resp.data.results[0].city_name));
        setcity_id_c(resp.data.results[0].city);
        setconsginee_st(toTitleCase(resp.data.results[0].state_name));
        setstate_id_f_c(resp.data.results[0].state_id);
        setconsignee_pincode(resp.data.results[0].pincode);
        setconsignee_p_id(resp.data.results[0].id);
        setto_pin(false);
      } else {
        dispatch(
          setDataExist(
            "You entered invalid pincode or pincode not available in database"
          )
        );
        dispatch(setAlertType("warning"));
        dispatch(setShowAlert(true));
        setcity("");
        setcity_id("");
        setstate(toTitleCase(resp.data.results[0].state_name));
        setstate_id(resp.data.results[0].state_id);
      }
    } catch (err) {
      console.warn(`Error Occur in Get Pincode, ${err}`);
    }
  };

  useLayoutEffect(() => {
    if (city_id_c !== 0) {
      setpincode_page_c(1);
      setpincodec_count(1);
      setpincodec_bottom(103);
      setloadc_pincode(true);
    }
  }, [city_id_c]);

  useEffect(() => {
    const total_packages = row1.reduce(
      (total_pkg, item) => total_pkg + (item[4] ? parseInt(item[4]) : 0),
      0
    );
    settotal_packages(total_packages);
    const total_net_weight = row1.reduce(
      (total_Weight, item) =>
        total_Weight + (item[2] ? parseFloat(item[2]) : 0),
      0
    );
    settotal_net_weight(total_net_weight);
    const total_ch_weight = row1.reduce(
      (total_weight, item) =>
        total_weight + (item[3] ? parseFloat(item[3]) : 0),
      0
    );
    settotal_ch_weight(total_ch_weight);
  }, [row1, dimension_list1]);

  useEffect(() => {
    if (delivery_mode != "") {
      setdelivery_mode_error(false);
    }
    if (city != "") {
      setconsignor_city_error(false);
    }
  }, [delivery_mode, city]);

  const get_client = async () => {
    let client_list = [];
    try {
      const resp = await axios.get(
        ServerAddress +
          `master/get_shipperconsignee/?search=${search_billto_customer}&p=${billto_customer_page}&records=${10}&c_type=${ctype}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log("resp===gopal", resp);
      if (resp.data.next === null) {
        setbillto_customer_loaded(false);
      } else {
        setbillto_customer_loaded(true);
      }
      if (resp.data.results.length > 0) {
        setbill_to_all_data(resp.data.results);
        if (billto_customer_page === 1) {
          client_list = resp.data.results.map((v) => [
            v.id,
            toTitleCase(v.name),
          ]);
        } else {
          client_list = [
            ...billto_customer_list,
            ...resp.data.results.map((v) => [v.id, toTitleCase(v.name)]),
          ];
        }
        setbillto_customer_count(billto_customer_count + 2);
        setbillto_customer_list(client_list);
      } else {
        setbillto_customer_list([]);
      }
    } catch (err) {
      alert(`Error Occur in Get Client, ${err}`);
    }
  };
  //This Function is Used to Invok the Bill to / customer data
  useEffect(() => {
    if (ctype) {
      get_client();
    }
  }, [search_billto_customer, billto_customer_page, ctype]);

  const getStates = () => {
    let state_list = [];
    axios
      .get(
        ServerAddress +
          `master/all_shipper_states/?search=${state_search_item}&p=${state_page}&records=${10}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((resp) => {
        settogstate(true);
        if (resp.data.next === null) {
          setstate_loaded(false);
        } else {
          setstate_loaded(true);
        }
        if (resp.data.results.length > 0) {
          if (state_page === 1) {
            state_list = resp.data.results.map((v) => [
              v.id,
              toTitleCase(v.state),
            ]);
          } else {
            state_list = [
              ...state_list_s,
              ...resp.data.results.map((v) => [v.id, toTitleCase(v.state)]),
            ];
          }
          setstate_count(state_count + 2);
          setstate_list_s(state_list);
        } else {
          setstate_list_s([]);
        }
      })
      .catch((err) => {
        console.warn(`Error Occur in Get States, ${err}`);
      });
  };

  const getStates_c = () => {
    let state_list = [];
    axios
      .get(
        ServerAddress +
          `master/all_consignee_states/?search=${state_search_item_c}&p=${state_page_c}&records=${10}&order_type=${delivery_type}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((resp) => {
        settogstate_c(true);
        if (resp.data.results.length > 0) {
          if (resp.data.next === null) {
            setstatec_loaded(false);
          } else {
            setstatec_loaded(true);
          }
          if (state_page_c === 1) {
            state_list = resp.data.results.map((v) => [
              v.id,
              toTitleCase(v.state),
            ]);
          } else {
            state_list = [
              ...state_list_c,
              ...resp.data.results.map((v) => [v.id, toTitleCase(v.state)]),
            ];
          }
          setstatec_count(statec_count + 2);
          setstate_list_c(state_list);
        } else {
          setstate_list_c([]);
        }
      })
      .catch((err) => {
        console.warn(`Error Occur in Get States, ${err}`);
      });
  };

  //Get Branch
  const getBranches = (type) => {
    let branch_list = [];
    axios
      .get(
        ServerAddress +
          `master/all_branches/?search=${
            type === "from" ? from_branch_search : to_branch_search
          }&p=${
            type === "from" ? from_branch_page : to_branch_page
          }&records=${10}&branch_name=${[""]}&branch_city=${[
            "",
          ]}&data=all&is_hub=True`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((response) => {
        if (type === "from") {
          if (response.data.next === null) {
            setfrom_branch_loaded(false);
          } else {
            setfrom_branch_loaded(true);
          }
          if (response.data.results.length > 0) {
            if (from_branch_page === 1) {
              branch_list = response.data.results.map((v) => [
                v.id,
                toTitleCase(v.branch_name),
              ]);
            } else {
              branch_list = [
                ...from_branch_list,
                ...response.data.results.map((v) => [
                  v.id,
                  toTitleCase(v.branch_name),
                ]),
              ];
            }
            setfrom_branch_count(from_branch_count + 2); // Increment page number for next request
            setfrom_branch_list(branch_list);
          } else {
            setfrom_branch_list([]);
          }
        } else {
          if (response.data.next === null) {
            setto_branch_loaded(false);
          } else {
            setto_branch_loaded(true);
          }
          if (response.data.results.length > 0) {
            if (to_branch_page === 1) {
              branch_list = response.data.results.map((v) => [
                v.id,
                toTitleCase(v.branch_name),
              ]);
            } else {
              branch_list = [
                ...to_branch_list,
                ...response.data.results.map((v) => [
                  v.id,
                  toTitleCase(v.branch_name),
                ]),
              ];
            }
            setto_branch_count(to_branch_count + 2); // Increment page number for next request
            setto_branch_list(branch_list);
          } else {
            setto_branch_list([]);
          }
        }
      })
      .catch((error) => {
        console.warn(`get_to city err, ${error}`);
      });
  };

  const get_op_city = (type) => {
    let temp = [];
    axios
      .get(
        ServerAddress +
          `master/get_br_name_op_city/?search=${
            type === "from" ? origincity_search : destinationcity_search
          }&p=${
            type === "from" ? origincity_page : destinationcity_page
          }&records=${10}&branch_id=${
            type === "from" ? from_branch_id : to_branch_id
          }`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        if (type === "from") {
          if (response.data.next === null) {
            setorigincity_loaded(false);
          } else {
            setorigincity_loaded(true);
          }
          if (response.data.results.length > 0) {
            if (origincity_page == 1) {
              temp = response.data.results.map((v) => [
                v.id,
                toTitleCase(v.city),
              ]);
            } else {
              temp = [
                ...origincity_list,
                ...response.data.results.map((v) => [
                  v.id,
                  toTitleCase(v.city),
                ]),
              ];
            }
            setorigincity_count(origincity_count + 2);
            setorigincity_list(temp);
          } else {
            setorigincity_list([]);
          }
        } else {
          if (response.data.next === null) {
            setdestinationcity_loaded(false);
          } else {
            setdestinationcity_loaded(true);
          }
          if (response.data.results.length > 0) {
            if (destinationcity_page == 1) {
              temp = response.data.results.map((v) => [
                v.id,
                toTitleCase(v.city),
              ]);
            } else {
              temp = [
                ...destinationcity_list,
                ...response.data.results.map((v) => [
                  v.id,
                  toTitleCase(v.city),
                ]),
              ];
            }
            setdestinationcity_count(destinationcity_count + 2);
            setdestinationcity_list(temp);
          } else {
            setdestinationcity_list([]);
          }
        }
      })
      .catch((error) => {
        console.warn(`get_to OP city err, ${error}`);
      });
  };

  useEffect(() => {
    if (from_branch_id !== "" && from_branch_id !== null) {
      get_op_city("from");
    }
  }, [from_branch_id, origincity_page, origincity_search]);

  useEffect(() => {
    if (to_branch_id !== "" && to_branch_id !== null) {
      get_op_city("to");
    }
  }, [to_branch_id, destinationcity_page, destinationcity_search]);

  useEffect(() => {
    if (
      user.department_name + " " + user.designation_name === "CLIENT BOOKING" ||
      user.department_name + " " + user.designation_name ===
        "Thirdparty Booking"
    ) {
      setcurrent_status("NOT APPROVED");
      setstatus_toggle(true);
    } else if (
      user.department_name === "DATA ENTRY" ||
      user.department_name === "DATA ENTRY" ||
      user.is_superuser
    ) {
      setcurrent_status("APPROVED");
      setstatus_toggle(true);
    } else {
      setcurrent_status("NOT APPROVED");
      // setstatus_toggle(false)
    }
  }, [user, isupdating]);

  const checkCustomerExistence = async () => {
    try {
      const response = await axios.post(
        ServerAddress + "master/get_customeridproof/",
        {
          id_proof: type_identity_consignor_no,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data.status === "success") {
        // Assuming get_shipperConsignor fetches customer details based on ID proof
        get_shipperConsignorid(type_identity_consignor_no);
      } else {
        // Clear customer details in your state or wherever they are stored
        // clearCustomerDetails();
      }
    } catch (error) {
      alert(`Error occurred while checking customer existence: ${error}`);
    }
  };

  const get_shipperConsignorid = async (idProof) => {
    // Add idProof as a parameter
    try {
      const response = await axios.get(
        `${ServerAddress}master/get_customeridproofget/?p=${consignor_page}&records=${10}&search=${idProof}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      const consignorData1 = response.data.results;

      // Filter consignorData based on the origin_city directly
      const filteredConsignorData = consignorData1.filter(
        (item) =>
          item.id_proof &&
          item.id_proof.toUpperCase() ===
            type_identity_consignor_no.toUpperCase()
      );
      setconsignordataidwise(filteredConsignorData);

      const shipperList = filteredConsignorData.map((v) => [
        v.id,
        toTitleCase(v.name),
        v.address_line1,
        v.phone_number,
      ]);
      setconsignor_list(shipperList);
    } catch (err) {
      console.warn(`Error Occur in Get client_consignor, ${err}`);
    }
  };

  useEffect(() => {
    if (type_identity_consignor_no.length >= 10) {
      checkCustomerExistence();
    } else {
    }
  }, [type_identity_consignor_no]);

  useEffect(() => {
    if (consignor_id !== "") {
      let selected_consignor = consignordata.filter(
        (value) => value.id === consignor_id
      );
      setconsignor_details(selected_consignor[0]);
    }
  }, [consignor_id, consignordata]);

  useEffect(() => {
    let selected_consignee = consigneedata.filter(
      (val) => val.id === consignee_id
    );
    setconsignee_details(selected_consignee[0]);
  }, [consignee_id, consigneedata]);

  // const getItem = () => {
  //   let temp = [];
  //   axios
  //     .get(
  //       ServerAddress +
  //         `master/get-item/?search=${search_item}&p=${item_page}&records=${10}&data=${"all"}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     )
  //     .then(function (response) {
  //       if (response.data.next === null) {
  //         setitem_loaded(false);
  //       } else {
  //         setitem_loaded(true);
  //       }
  //       if (response.data.results.length > 0) {
  //         if (item_page == 1) {
  //           temp = response.data.results.map((v) => [
  //             v.id,
  //             toTitleCase(v.item_name),
  //           ]);
  //         } else {
  //           temp = [
  //             ...item_list,
  //             ...response.data.results.map((v) => [
  //               v.id,
  //               toTitleCase(v.item_name),
  //             ]),
  //           ];
  //         }
  //         setitem_list(temp);
  //       } else {
  //         setitem_list([]);
  //       }
  //     })
  //     .catch((error) => {
  //       alert(`Error occured while posting data Data ${error}`);
  //     });
  // };

  const get_ItemPacking = (id) => {
    let op_temp = [];
    let data = [];
    axios
      .get(
        ServerAddress +
          `master/get_item_packing/?search=${packingmode_search_item}&item_id=${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((response) => {
        data = response.data.packing_types;
        if (data.length > 0) {
          if (data[0]?.packing_type) {
            op_temp = data.map((v) => [
              v.packing_type__id,
              toTitleCase(v.packing_type__packing_name),
            ]);
            setpackingmodeList(op_temp);
          }
        }
      })
      .catch((err) => {
        console.warn(`Error Occur in Get packing, ${err}`);
      });
  };

  useLayoutEffect(() => {
    setpkgitem_id(row1[row1.length - 1][0][0]);
  }, [dimension_list1]);

  useLayoutEffect(() => {
    if (pkgitem_id) {
      get_ItemPacking(pkgitem_id);
    }
  }, [pkgitem_id, packingmode_search_item]);

  const getPackingMode = () => {
    let packing_temp = [];
    axios
      .get(
        ServerAddress +
          `master/get_packing/?search=${search_packingmode}&p=${packingmode_page}&records=${10}&data=${"all"}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        if (response.data.next === null) {
          setpackingmode_loaded(false);
        } else {
          setpackingmode_loaded(true);
        }
        if (response.data.results.length > 0) {
          if (packingmode_page == 1) {
            packing_temp = response.data.results.map((v) => [
              v.id,
              toTitleCase(v.packing_name),
            ]);
          } else {
            packing_temp = [
              ...packingmode_list,
              ...response.data.results.map((v) => [
                v.id,
                toTitleCase(v.packing_name),
              ]),
            ];
          }
          setpackingmode_list(packing_temp);
        } else {
          setpackingmode_list([]);
        }
      })
      .catch((error) => {
        alert(`Error occured while get packaging mode Data ${error}`);
      });
  };

  // useEffect(() => {
  //   getItem();
  // }, [item_page, search_item]);

  useEffect(() => {
    getPackingMode();
  }, [packingmode_page, search_packingmode]);

  const privatemarkValueOwn = () => {
    return gr_no_value + "/" + "package" + "/" + city_c;
  };

  useEffect(() => {
    if (marktype == "OWN MARK") {
      setprivatemarka_value_own(privatemarkValueOwn());
    }
  }, [gr_no_value, city_c, marktype]);

  const send_order_data = (values) => {
    setIsLoading(true);
    axios
      .post(
        ServerAddress + "booking/add_order/",
        {
          gr_no: entry_type_btn === "AUTO GENERATE" ? "" : gr_no_value,
          entry_type: entry_type_btn,
          topay_amount: topay_amount ?? 0,
          booking_through: booking_through,
          order_type: "CLIENT BOOKING",
          invoice_list: invoice_list,
          marka_list: marka_list,
          customer_type: ctype,
          booking_type: String(type_of_booking).toUpperCase(),
          booking_at: booking_date,
          delivery_mode: String(type_of_deliverymode).toUpperCase(),
          order_created_branch: user.home_branch,
          from_branch: from_branch_id,
          to_branch: to_branch_id,
          from_branch_name: from_branch ? from_branch.toUpperCase() : "",
          to_branch_name: to_branch ? to_branch.toUpperCase() : "",
          item: item_id ?? null,
          freight_upto: city_id_f,
          rate_type: type_of_rate,
          pay_by: pay_by.toUpperCase(),
          name: marktype,
          private_marking: privatemarka_value_own,
          package_details: row1,
          order_channel: "WEB APP",
          current_branch: home_branch_id,
          order_created_branch: home_branch_id,
          branch_name: user.home_branch_name,
          created_by: user.id,
          total_quantity: values?.total_quantity ? values.total_quantity : 0,
          total_packages,
          total_net_weight,
          total_ch_weight,

          ptl_branch:
            ctype === "WALKING" && type_of_rate === "PTL" ? type_ptl : "",
          ftl_branch:
            ctype === "WALKING" && type_of_rate === "FTL" ? type_ftl : "",
          ptl_client:
            ctype === "CONTRACTUAL" && type_of_rate === "PTL" ? type_ptl_c : "",
          ftl_client:
            ctype === "CONTRACTUAL" && type_of_rate === "FTL" ? type_ftl_c : "",
          ptl_range: ctype === "WALKING" ? type_ptlrange : type_ptlrange_c,

          billto: billto_customer_id,
          cm_current_department: user.department,
          cm_current_status: current_status.toUpperCase(),
          cm_transit_status: current_status.toUpperCase(),
          consignor: consignor_id,
          // ctype === "CONTRACTUAL"
          //   ? consignor_id
          //   : consignor_name.toUpperCase(),
          consignee: consignee_id,
          // ctype === "CONTRACTUAL"
          //   ? consignee_id
          //   : consignee_name.toUpperCase(),
          bkp_consignor_name: consignor_name,
          bkp_consignee_name: consignee_name,
          ftl_client: type_ftl_c,
          // consignor_id_proof:
          //   ctype === "WALKING" ? type_identity_consignor_no : "",
          // consignee_id_proof:
          //   ctype === "WALKING" ? type_identity_consignee_no : "",
          // consignor_id_type: ctype === "WALKING" ? type_identity_consignor : "",
          // consignee_id_type: ctype === "WALKING" ? type_identity_consignee : "",
          // consignor_mobile: ctype === "WALKING" ? consignor_phone_no : "",
          // consignee_mobile: ctype === "WALKING" ? consignee_phone_number : "",
          // consignor_address1:
          //   ctype === "WALKING" ? consignor_add_1.toUpperCase() : "",
          // consignee_address1:
          //   ctype === "WALKING" ? consignee_address_line1.toUpperCase() : "",
          // consignor_address2:
          //   ctype === "WALKING" ? consignor_add_2.toUpperCase() : "",
          // consignee_address2:
          //   ctype === "WALKING" ? consignee_address_line2.toUpperCase() : "",
          // from_state: ctype === "WALKING" ? state_id : partyDetails.state,
          // to_state: ctype === "WALKING" ? state_id_f_c : partyDetails2.state,
          // from_city: ctype === "WALKING" ? city_id : partyDetails.city,
          // to_city: ctype === "WALKING" ? city_id_c : partyDetails2.city,
          // from_pincode:
          //   ctype === "WALKING" ? pincode_id : partyDetails.pincode_id,
          // to_pincode:
          //   ctype === "WALKING" ? consignee_p_id : partyDetails2.pincode_id,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        setIsLoading(false);
        const responseData = response.data.data; // Accessing the inner 'data' object
        if (response.data.status === "success") {
          navigate("/booking/clientbooking");
          dispatch(setToggle(true));
          setsubmit_btn(true);
          dispatch(setShowAlert(true));
          dispatch(setDataExist(`Order  ${gr_no_value} Added successfully`));
          dispatch(setAlertType("success"));
          setShowOrder(true);
        } else {
          // Handle failure
          alert("Error: " + responseData.error);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert(`Error occurred while posting order data: ${error}`);
      });
  };

  // Update Order
  const update_order = (values) => {
    setIsLoading(true);
    let id = order.id;
    let fields_names = Object.entries({
      booking_type: type_of_booking,
      delivery_mode: type_of_deliverymode,
      from_branch_name: from_branch,
      to_branch_name: to_branch,
      total_packages: total_packages,
      total_net_weight: total_net_weight,
      total_ch_weight: total_ch_weight,
      consignor_name: consignor_name,
      consignee_name: consignee_name,
    });
    let change_fields = {};
    for (let j = 0; j < fields_names.length; j++) {
      const ele = fields_names[j];
      let prev = location.state.order[`${ele[0]}`];
      let new_v = ele[1];
      if (String(prev).toUpperCase() != String(new_v).toUpperCase()) {
        change_fields[`${ele[0]}`] = new_v?.toString().toUpperCase();
      }
    }

    let inv_data = Booking_data?.invoice;
    let pkg_data = Booking_data?.packages;
    let marka_data = Booking_data?.marka;

    let invoice_details = [];
    let packages = [];
    let marka = [];

    if (inv_data?.length > 0) {
      for (let index = 0; index < inv_data.length; index++) {
        const element = inv_data[index];
        const match = invoice_list.find((v) => v["id"] === element["id"]);
        console.log("element=", element);
        console.log("match=", match);
        if (
          !match ||
          String(match["ewaybill_no"]) !==
            String(element["ewaybill_no"] ? element["ewaybill_no"] : "") ||
          String(match["invoice_no"]) !== String(element["invoice_no"]) ||
          String(match["invoice_at"]) !==
            String(element["invoice_at"].split("T")[0]) ||
          String(match["invoice_amount"]) !== String(element["invoice_amount"])
        ) {
          if (match) {
            const modifiedMatch = { ...match };
            invoice_details.push(modifiedMatch);
          }
        }
      }
    }

    if (pkg_data?.length > 0) {
      for (let index = 0; index < pkg_data.length; index++) {
        const element = pkg_data[index];
        const match = row1.find((v) => v[5] === element["id"]);
        if (
          !match ||
          String(match[4]) !== String(element["no_of_package"]) ||
          String(match[0][1]).toUpperCase() !==
            String(element["item_name"]).toUpperCase() ||
          String(match[1][1]).toUpperCase() !==
            String(element["packing_name"]).toUpperCase() ||
          String(match[2]) !== String(element["weight"]) ||
          String(match[3]) !== String(element["chargable_weight"])
        ) {
          if (match) {
            const modifiedMatch = [...match];
            packages.push(modifiedMatch);
          }
        }
      }
    }

    if (marka_data?.length > 0) {
      for (let index = 0; index < marka_data.length; index++) {
        const element = marka_data[index];
        const match = marka_list.find((v) => v["id"] === element["id"]);
        if (
          !match ||
          String(match["name"]).toUpperCase() !==
            String(element["name"]).toUpperCase() ||
          String(match["marking"]).toUpperCase() !==
            String(element["marking"]).toUpperCase()
        ) {
          if (match) {
            const modifiedMatch = { ...match };
            marka.push(modifiedMatch);
          }
        }
      }
    }

    change_fields["invoice"] = invoice_details;
    change_fields["packages"] = packages;
    change_fields["marka"] = marka;
    console.log("change_fields===", change_fields);

    axios
      .put(
        ServerAddress + "booking/update_order/" + id,
        {
          change_fields: change_fields,
          gr_no: gr_no_value,
          entry_type: entry_type_btn,
          topay_amount: topay_amount ?? 0,
          booking_through: booking_through,
          invoice_list: invoice_list,
          marka_list: marka_list,
          delete_marka_ids: delete_marka_ids,
          invoice_ids: invoice_ids,
          deleted_dimension_ids: deleted_dimension_ids,
          customer_type: ctype,
          booking_type: String(type_of_booking).toUpperCase(),
          booking_at: booking_date,
          delivery_mode: String(type_of_deliverymode).toUpperCase(),
          order_created_branch: user.home_branch,
          from_branch: from_branch_id,
          to_branch: to_branch_id,
          item: item_id ?? null,
          freight_upto: city_id_f,
          rate_type: type_of_rate,
          pay_by: pay_by.toUpperCase(),
          order_channel: "WEB APP",
          current_branch: home_branch_id,
          order_created_branch: home_branch_id,
          branch_name: user.home_branch_name,
          modified_by: user.id,
          total_quantity: values?.total_quantity ? values.total_quantity : 0,

          ptl_branch:
            ctype === "WALKING" && type_of_rate === "PTL" ? type_ptl : "",
          ftl_branch:
            ctype === "WALKING" && type_of_rate === "FTL" ? type_ftl : "",
          ptl_client:
            ctype === "CONTRACTUAL" && type_of_rate === "PTL" ? type_ptl_c : "",
          ftl_client:
            ctype === "CONTRACTUAL" && type_of_rate === "FTL" ? type_ftl_c : "",
          ptl_range: ctype === "WALKING" ? type_ptlrange : type_ptlrange_c,

          billto: billto_customer_id,
          cm_current_department: user.department,
          cm_current_status: current_status.toUpperCase(),
          cm_transit_status: current_status.toUpperCase(),
          consignor: consignor_id,
          // ctype === "CONTRACTUAL"
          //   ? consignor_id
          //   : consignor_name.toUpperCase(),
          consignee: consignee_id,
          // ctype === "CONTRACTUAL"
          //   ? consignee_id
          //   : consignee_name.toUpperCase(),
          bkp_consignor_name: consignor_name,
          bkp_consignee_name: consignee_name,
          // consignor_id_proof:
          //   ctype === "WALKING" ? type_identity_consignor_no : "",
          // consignee_id_proof:
          //   ctype === "WALKING" ? type_identity_consignee_no : "",
          // consignor_id_type: ctype === "WALKING" ? type_identity_consignor : "",
          // consignee_id_type: ctype === "WALKING" ? type_identity_consignee : "",
          // consignor_mobile: ctype === "WALKING" ? consignor_phone_no : "",
          // consignee_mobile: ctype === "WALKING" ? consignee_phone_number : "",
          // consignor_address1:
          //   ctype === "WALKING" ? consignor_add_1.toUpperCase() : "",
          // consignee_address1:
          //   ctype === "WALKING" ? consignee_address_line1.toUpperCase() : "",
          // consignor_address2:
          //   ctype === "WALKING" ? consignor_add_2.toUpperCase() : "",
          // consignee_address2:
          //   ctype === "WALKING" ? consignee_address_line2.toUpperCase() : "",
          // from_state: ctype === "WALKING" ? state_id : partyDetails.state,
          // to_state: ctype === "WALKING" ? state_id_f_c : partyDetails2.state,
          // from_city: ctype === "WALKING" ? city_id : partyDetails.city,
          // to_city: ctype === "WALKING" ? city_id_c : partyDetails2.city,
          // from_pincode:
          //   ctype === "WALKING" ? pincode_id : partyDetails.pincode_id,
          // to_pincode:
          //   ctype === "WALKING" ? consignee_p_id : partyDetails2.pincode_id,
          package_details: row1,
          total_packages,
          total_net_weight,
          total_ch_weight,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        setIsLoading(false);
        if (response.data.status === "success") {
          // send_order_image(order.gr_no);
          dispatch(setToggle(true));
          dispatch(setDataExist(`Order Updated Sucessfully`));
          dispatch(setAlertType("info"));
          dispatch(setShowAlert(true));
          navigate("/booking/clientbooking");
        }
      })
      .catch(function () {
        setIsLoading(false);
        alert(" Error While  Updateing Orders");
      });
  };

  useEffect(() => {
    if (location.state === null) {
      setfrom_branch(toTitleCase(user.home_branch_name));
      setfrom_branch_id(home_branch_id);
    }
  }, [user]);

  useLayoutEffect(() => {
    getStates();
    setcity_list_s([]);
  }, [state_page, state_search_item, refresh]);

  useLayoutEffect(() => {
    getStates_c();
    setcity_list__c([]);
  }, [state_page_c, state_search_item_c, refresh_c]);

  useEffect(() => {
    delivery_list();
  }, [delivery_mode]);

  useEffect(() => {
    if (delivery_mode != "") {
      setdelivery_mode_error(false);
    }
    //-----Walking
    if (consignor_name != "") {
      setconsignor_name_error(false);
    }
  }, [delivery_mode, consignor_name]);

  const fetchQuotationDetails = async () => {
    try {
      if (
        !from_branch_id ||
        !city_id_c ||
        !type_of_rate ||
        !(type_ptl || type_ftl || type_ptlrange)
      ) {
        return;
      }

      const response = await axios.get(
        ServerAddress +
          `master/get_rate_master/?search=${search}&p=${page_num}&records=${data_len}&branch_id=${from_branch_id}&destination_city=${city_id_c}&rate_type="BRANCH"&quotation_type=${
            type_ptl || type_ftl || type_ptlrange
          }`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const data = response.data.results;

      // Process data and set state
      const mappedData = data.map((item) => ({
        id: item.id,
        item: item.item,
        item_type_name: toTitleCase(item.item_type_name),
        packing_type: item.packing_type,
        packing_type_name: toTitleCase(item.packing_type_name),
        quotation_type: type_ptl || type_ftl || type_ptlrange,
        rate: item.rate,
      }));

      setquotation_datails(mappedData);
    } catch (error) {
      console.error("Error fetching rate details:", error);
    }
  };

  useEffect(() => {
    fetchQuotationDetails();
  }, [
    from_branch_id,
    city_id_c,
    type_of_rate,
    type_ptl,
    type_ftl,
    type_ptlrange,
  ]);

  // Get Commodity
  const getItemDataClient = () => {
    let data = [];
    let temp3 = [];
    axios
      .get(
        ServerAddress +
          `master/get_clientitem/?search=${search_item}&p=${item_page}&records=${10}&ctype=${ctype}&consignor=${consignor_id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((response) => {
        console.log("CLIENTRESP===", response.data.results);
        if (response.data.next === null) {
          setitem_loaded(false);
        } else {
          setitem_loaded(true);
        }
        if (response.data.results.length > 0) {
          data = response.data.results;
          console.log("DAT===", data);
          if (item_page === 1) {
            temp3 = data.map((v) => [v.id, toTitleCase(v.item_name), v.gst_exempt]);
          } else {
            temp3 = [
              ...item_list,
              ...data.map((v) => [v.id, toTitleCase(v.item_name), v.gst_exempt]),
            ];
          }
          setitem_count(item_count + 2);
          setitem_list(temp3);
        } else {
          setitem_list([]);
        }
      })
      .catch((err) => {
        console.warn(`Error Occur in Get ClientItem Data ${err}`);
      });
  };

  useEffect(() => {
    if (consignor_id !== 0 && consignor_id && ctype === "CONTRACTUAL") {
      getItemDataClient();
    }
  }, [item_page, search_item, consignor_id, ctype]);

  useEffect(() => {
    if (consignor_id !== 0) {
      if (togitem) {
        setitem("");
        setitems_id("");
      }
    }
  }, [consignor_id]);

  useEffect(() => {
    if (isupdating) {
      settogitem(false);
    }
  }, []);

  useEffect(() => {
    const subTotal =
      parseFloat(freight_value) +
      parseFloat(sur_amount) +
      parseFloat(doc_charge) +
      parseFloat(toll) +
      parseFloat(gr_charge) +
      parseFloat(pf_amnt) +
      parseFloat(cartage_fuel) +
      parseFloat(delivery) +
      parseFloat(other_charge);
    settotal(subTotal);
    setsgst((subTotal * 9) / 100);
    setcgst((subTotal * 9) / 100);
  }, [
    freight_value,
    sur_amount,
    doc_charge,
    toll,
    gr_charge,
    pf_amnt,
    cartage_fuel,
    delivery,
    other_charge,
  ]);

  useEffect(() => {
    setigst(parseFloat(cgst) + parseFloat(sgst));
  }, [cgst, sgst]);

  useEffect(() => {
    settotal(Math.round(total + igst));
  }, [igst, total]);

  // useEffect(() => {
  //   if (state_id !== "" && by_pincode === false) {
  //     setcity_page(1);
  //     setpincode("");
  //     setpincode_list_s([]);
  //   }
  // }, [state_id, city_page, city_search_item]);

  useEffect(() => {
    if (location.state === null) {
      setorigincity("");
      setorigincity_id("");
      setconsignor_state_id("");
      setconsignor_id("");
      setdestinationcity("");
      setdestinationcity_id("");
      setconsignee_id("");
    }
  }, []);

  useEffect(() => {
    setall_consignor_details(
      consignor_state + "," + consignor_city + "," + consignor_pincode
    );
  }, [consignor_state, consignor_city, consignor_pincode]);

  useEffect(() => {
    setall_consignee_details(
      consignee_state + "," + consignee_city + "," + consignee_pincode
    );
  }, [consignee_state, consignee_city, consignee_pincode]);

  const calculateTotalAmount = () => {
    let selectedRate = 0;
    let selectedtoll = 0;

    // Find the selected rate from the quotation_datails based on the selected item
    const selectedItem = quotation_datails.find(
      (detail) => detail.item_type_name === items
    );

    // If a matching item is found and it has a valid rate, use that rate
    if (
      selectedItem &&
      selectedItem.rate &&
      !isNaN(parseFloat(selectedItem.rate))
    ) {
      selectedRate = parseFloat(selectedItem.rate);
    }
    if (
      selectedItem &&
      selectedItem.toll &&
      !isNaN(parseFloat(selectedItem.toll))
    ) {
      selectedtoll = parseFloat(selectedItem.toll);
    }

    // Add other charges to the selected rate to calculate the total amount
    const total =
      selectedRate +
      parseFloat(sur_amount) +
      parseFloat(doc_charge) +
      selectedtoll +
      parseFloat(gr_charge) +
      parseFloat(pf_amnt) +
      parseFloat(cartage_fuel) +
      parseFloat(delivery) +
      parseFloat(other_charge) +
      parseFloat(cgst) +
      parseFloat(sgst);

    // Set the total amount state
    setTotal_amount(total);
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [
    items,
    consignor_packingmode,
    freight_value,
    sur_amount,
    doc_charge,
    toll,
    gr_charge,
    pf_amnt,
    cartage_fuel,
    delivery,
    other_charge,
    cgst,
    sgst,
    // igst,
  ]);

  useEffect(() => {
    let selected_consignee = consigneedata.filter(
      (val) => val.id === consignee_id
    );
    setconsignee_details(selected_consignee[0]);
  }, [consignee_id, consigneedata]);

  useEffect(() => {
    if (state_id_f_c !== "" && by_pincode_f_c === false) {
      setcity_page_c(1);
      setpincode_list_f_c([]);
    }
  }, [state_id_f_c, city_page_c, state_search_item_c]);

  const get_shipperConsignor = async () => {
    try {
      const response = await axios.get(
        ServerAddress +
          `master/get_single_client_details/?client_id=${
            isupdating ? consignor_id : user.client
          }`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.status === 200) {
        setPartyDetails(response.data.client_data);
        setconsignor_name(response.data.client_data?.client_name);
        setconsignor_id(response.data.client_data?.id);
      }
    } catch (err) {
      console.warn(`Error Occur in Get client_consignor, ${err}`);
    }
  };

  useEffect(() => {
    if (ctype && isupdating ? consignor_id : user.client) {
      get_shipperConsignor();
    }
  }, [consignor_page, consignor_id, user.client]);

  const get_consignee_client = () => {
    let com_list = [];
    axios
      .get(
        ServerAddress +
          `master/get_order_consignee_client/?search=${search_consignee}&p=${consignee_page}&records=${10}&client_id=${consignor_id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((resp) => {
        console.log("resp=====", resp);
        if (resp.data.results.length > 0) {
          if (resp.data.next === null) {
            setconsignee_loaded(false);
          } else {
            setconsignee_loaded(true);
          }
          if (consignee_page === 1) {
            com_list = resp.data.results.map((v) => [
              v.id,
              toTitleCase(v.client_name) +
                "_" +
                toTitleCase(v.client_type) +
                "_" +
                v.id_proof.toUpperCase(),
            ]);
          } else {
            com_list = [
              ...consignee_list,
              ...resp.data.results.map((v) => [
                v.id,
                toTitleCase(v.client_name) +
                  "_" +
                  toTitleCase(v.client_type) +
                  "_" +
                  v.id_proof.toUpperCase(),
              ]),
            ];
          }
          setconsignee_count(consignee_count + 2);
          setconsignee_list(com_list);
        } else {
          setconsignee_list([]);
        }
      })
      .catch((err) => {
        console.warn(`Error Occur in Get Consignee Client, ${err}`);
      });
  };
  const get_consignee_client_details = () => {
    axios
      .get(
        ServerAddress +
          `master/get_single_client_details/?&client_id=${consignee_id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          setPartyDetails2(resp.data.client_data);
        }
      })
      .catch((err) => {
        console.warn(`Error Occur in Get Consignee Details, ${err}`);
      });
  };
  useEffect(() => {
    if (consignee_id !== "" && consignee_id !== null && consignee_id !== 0) {
      get_consignee_client_details();
    }
  }, [consignee_id]);
  useEffect(() => {
    if (consignor_id !== "" && consignor_id !== null && consignor_id !== 0) {
      get_consignee_client();
    }
  }, [consignor_id, consignee_page, search_consignee]);
  useLayoutEffect(() => {
    try {
      let order_data = location.state.order;
      setisupdating(true);
      setBooking_data(order_data);
      setbillto_customer(order_data?.billto_name);
      setbillto_customer_id(order_data?.billto);
      setmarktype(order_data?.name);
      settopay_amount(order_data?.topay_amount);
      setprivatemarka_value_own(order_data?.private_marking);
      setstate(order_data?.consignor_state);
      setlocation_id_f(order_data?.consignor_location);
      setcity(order_data?.from_city_name);
      setpay_by(order_data?.pay_by);
      setitem_id(order_data?.item);
      setitem(order_data?.item_name ? toTitleCase(order_data?.item_name) : "");
      settype_of_rate(order_data?.rate_type);
      setctype(order_data?.customer_type);

      settype_ptl(
        order_data?.customer_type === "WALKING" &&
          order_data?.rate_type === "PTL"
          ? order_data?.ptl_branch
          : ""
      );
      settype_ftl(
        order_data?.customer_type === "WALKING" &&
          order_data?.rate_type === "FTL"
          ? order_data?.ftl_branch
          : ""
      );
      settype_ptl_c(
        order_data?.customer_type === "CONTRACTUAL" &&
          order_data?.rate_type === "PTL"
          ? order_data?.ptl_client
          : ""
      );
      settype_ftl_c(
        order_data?.customer_type === "CONTRACTUAL" &&
          order_data?.rate_type === "FTL"
          ? order_data?.ftl_client
          : ""
      );
      settype_ptlrange(
        order_data?.customer_type === "WALKING" ? order_data?.ptl_range : ""
      );
      settype_ptlrange_c(
        order_data?.customer_type === "CONTRACTUAL" ? order_data?.ptl_range : ""
      );

      setcity_id_f(order_data?.freight_upto);
      setcity_f(order_data?.freight_upto_name);
      setconsignee_n(order_data?.consignee);
      setconsginee_st(order_data?.consignee_state);
      setlocation_id_to(order_data?.consignee_location);
      setlocation_id(order_data?.consignor_location);
      setconsignee_address(order_data?.consignee_address1);
      setconsginee_c(order_data?.consignee_city);
      setlocation_c(order_data?.consignee_location);
      setlocation_id_f_c(order_data?.consignee_location);
      setlocation_c(order_data?.consignor_location);
      setconsignor_address_line1(order_data?.consignor_address1);
      setewaybill_no(order_data?.eway_bill_no);
      setconsignor_pincode(order_data?.consignor_from_pincode);
      setbooking_through(order_data?.with_ewayBill);
      setitem_id(order_data?.item);
      setitem(order_data?.item_name);
      setdelivery_mode(toTitleCase(order_data?.delivery_mode));
      setorder(location.state.order);

      setcurrent_status(order_data?.current_status);
      settype_of_deliverymode(order_data?.delivery_mode);

      setorder_id(order_data?.id);
      settype_ptlrange(order_data?.ptl_range);
      setgr_no_value(order_data?.gr_no);
      dispatch(setCurOrderId(order_data?.id));
      dispatch(setCurOrderGRNo(order_data?.gr_no));
      settype_of_booking(order_data?.booking_type);
      setentry_type_btn(order_data?.entry_type);
      setactual_weigth(order_data?.actual_weight);
      setpay_by(order_data?.pay_by);

      //Consignor Info
      setconsignor_id(order_data?.consignor);
      setconsignor_name(toTitleCase(order_data?.consignor_name));
      settype_identity_consignor_no(order_data?.consignor_id_proof);
      settype_identity_consignor(order_data?.consignor_id_type);
      setconsignor_phone_no(order_data?.consignor_phone_number);
      setconsignor_add_1(order_data?.consignor_address1);
      setconsignor_add_2(order_data?.consignor_address2);
      setstate(toTitleCase(order_data?.consignor_state_name));
      setstate_id(order_data?.consignor_state);
      setconsignor_city(toTitleCase(order_data?.consignor_city_name));
      setcity_id(order_data?.consignor_city);
      setpincode_id(order_data?.consignor_pincode);
      setpincode(order_data?.consignor_pincode_name);

      //Consignee Info
      setconsignee_id(order_data?.consignee);
      setconsignee_name(toTitleCase(order_data?.consignee_name));
      settype_identity_consignee_no(
        toTitleCase(order_data?.consignee_id_proof)
      );
      settype_identity_consignee(toTitleCase(order_data?.consignee_id_type));
      setconsignee_phone_number(order_data?.consignee_phone_number);
      setconsignee_address_line1(order_data?.consignee_address1);
      setconsignee_address_line2(order_data?.consignee_address2);
      setconsginee_st(toTitleCase(order_data?.consignee_state_name));
      setstate_id_f_c(order_data?.consignee_state);
      setconsginee_c(toTitleCase(order_data?.consignee_city_name));
      setcity_id_c(order_data?.consignee_city);
      setconsignee_pincode(order_data?.consignee_pincode_name);
      setconsignee_p_id(order_data?.consignee_pincode);

      setfrom_branch_id(order_data?.from_branch);
      setfrom_branch(toTitleCase(order_data?.from_branch_name));
      setto_branch_id(order_data?.to_branch);
      setto_branch(toTitleCase(order_data?.to_branch_name));

      setinv_ewaybill_no(order_data?.inv_eway_bill_no);
      setinv_date(order_data?.inv_date);
      setinvoice_no(order_data?.inv_no);
      setinvoice_amount(order_data?.inv_amount);

      let temp_list = [];
      let temp = order_data?.packages;
      if (temp?.length > 0) {
        for (let index = 0; index < temp.length; index++) {
          temp_list.push([
            [
              temp[index]?.item,
              temp[index]?.item_name ? toTitleCase(temp[index].item_name) : "",
            ],
            [
              temp[index]?.packing_mode,
              temp[index]?.packing_name
                ? toTitleCase(temp[index].packing_name)
                : "",
            ],
            temp[index].weight,
            temp[index].chargable_weight,
            temp[index].no_of_package,
            temp[index].id,
          ]);
        }
        setrow1(temp_list);
      }

      let inv_list = [];
      let invoice = order_data?.invoice;
      if (invoice?.length > 0) {
        for (let index = 0; index < invoice.length; index++) {
          inv_list.push({
            ewaybill_no: invoice[index]?.ewaybill_no
              ? invoice[index]?.ewaybill_no
              : "",
            invoice_at: invoice[index]?.invoice_at
              ? invoice[index].invoice_at
              : "",
            invoice_no: invoice[index]?.invoice_no
              ? invoice[index].invoice_no
              : "",
            invoice_amount: invoice[index]?.invoice_amount
              ? invoice[index].invoice_amount
              : "",
            id: invoice[index].id,
          });
        }
        setinvoice_list(inv_list);
      }

      let marka_list = [];
      let marka = order_data?.marka;
      if (marka?.length > 0) {
        for (let index = 0; index < marka.length; index++) {
          marka_list.push({
            name: marka[index]?.name ? marka[index]?.name : "",
            marking: marka[index]?.marking ? marka[index].marking : "",
            id: marka[index].id,
          });
        }
        setmarka_list(marka_list);
      }

      let data = order_data?.booking_at;
      const dateTime = new Date(data);

      // Get the hours and minutes in the desired format
      const hours = String(dateTime.getUTCHours()).padStart(2, "0");
      const minutes = String(dateTime.getUTCMinutes()).padStart(2, "0");

      // Create the converted date and time string
      const convertedDateTime = `${dateTime
        .toISOString()
        .slice(0, 10)}T${hours}:${minutes}`;
      setbooking_date(convertedDateTime);
    } catch (error) {}
  }, []);

  const getPackingModesForSelectedItem = (selectedItem) => {
    if (selectedItem) {
      const packingModes = quotation_datails
        .filter((item) => item.item_type_name === selectedItem)
        .map((item) => item.packing_type_name);
      return [...new Set(packingModes)];
    }

    return [];
  };

  const handleRateChange = (e, index) => {
    const newQuotationDatails = [...quotation_datails];
    newQuotationDatails[index].rate = e.target.value;
    setquotation_datails(newQuotationDatails);
  };

  useEffect(() => {
    let selected_consignor = consignordata.filter(
      (val) => val.id === consignor_id
    );
    setconsignor_details(selected_consignor[0]);
  }, [consignor_id, consignordata]);

  const getFreightCities = async () => {
    let cities_list = [];
    try {
      const resp = await axios.get(
        ServerAddress +
          `master/all_cities/?search=${city_search_item_f}&p=${city_page_f}&records=${10}&data=all&city_search=${""}` +
          "&place_id=" +
          "&filter_by=" +
          "&state_name=",
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (resp.data.next === null) {
        setcity_f_loaded(false);
      } else {
        setcity_f_loaded(true);
      }

      if (resp.data.results.length > 0) {
        if (city_page_f === 1) {
          cities_list = resp.data.results.map((v) => [
            v.id,
            toTitleCase(v.city),
          ]);
        } else {
          cities_list = [
            ...freightuptocity_list,
            ...resp.data.results.map((v) => [v.id, toTitleCase(v.city)]),
          ];
        }
        setcity_f_count(city_f_count + 2);
        setfreightuptocity_list(cities_list);
      } else {
        setfreightuptocity_list([]);
      }
    } catch (err) {
      console.warn(`Error Occur in Get City, ${err}`);
    }
  };

  const getCities = (place_id, filter_by, val) => {
    setby_pincode(false);
    setby_pincode_f_c(false);
    let cities_list = [];
    axios
      .get(
        ServerAddress +
          `master/all_cities/?search=${""}&p=${
            val === "Shipper" ? city_page : city_page_c
          }&records=${10}&city_search=${
            val === "Shipper" ? city_search_item : city_search_item_c
          }&order_type=${delivery_type}` +
          "&place_id=" +
          place_id +
          "&filter_by=" +
          filter_by,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((resp) => {
        if (resp.data.results.length > 0) {
          if (val === "Shipper") {
            settogcity(true);
            if (resp.data.next === null) {
              setcity_loaded(false);
            } else {
              setcity_loaded(true);
            }
            if (city_page === 1) {
              cities_list = resp.data.results.map((v) => [
                v.id,
                toTitleCase(v.city),
              ]);
            } else {
              cities_list = [
                ...city_list_s,
                ...resp.data.results.map((v) => [v.id, toTitleCase(v.city)]),
              ];
            }
            setcity_count(city_count + 2);
            setcity_list_s(cities_list);
          } else {
            settogcity_c(true);
            if (resp.data.next === null) {
              setcityc_loaded(false);
            } else {
              setcityc_loaded(true);
            }
            if (city_page_c === 1) {
              cities_list = resp.data.results.map((v) => [
                v.id,
                toTitleCase(v.city),
              ]);
            } else {
              cities_list = [
                ...city_list__c,
                ...resp.data.results.map((v) => [v.id, toTitleCase(v.city)]),
              ];
            }
            setcityc_count(cityc_count + 2);
            setcity_list__c(cities_list);
          }
        } else {
          setcity_list_s([]);
          setcity_list__c([]);
        }
      })
      .catch((err) => {
        console.warn(`Error Occur in Get City, ${err}`);
      });
  };

  const DeletePackage = (item) => {
    let temp = [...row1];

    const idx = temp.indexOf(item);

    if (idx > -1) {
      temp.splice(idx, 1);
    }
    setrow1(temp);
    if (item?.length === 6) {
      setdeleted_dimension_ids((prev) => [...prev, item[5]]);
    }
  };

  const handleAction = () => {
    dispatch(setToggle(true));
    navigate(-1);
  };

  const getquickorders = () => {
    let temp = [];
    axios
      .get(
        ServerAddress +
          `booking/all_quickorders/?search=${""}&p=${quick_page}&records=${10}&data=all`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((response) => {
        if (response.data.results.length > 0) {
          if (quick_page == 1) {
            temp = response.data.results.map((v) => [
              v.id,
              v.ref_no,
              v.booking_type,
              toTitleCase(v.customer_type),
              toTitleCase(v.delivery_mode),
            ]);
          } else {
            temp = [
              ...quickorder,
              ...response.data.results.map((v) => [
                v.id,
                v.ref_no,
                v.booking_type,
                toTitleCase(v.customer_type),
                toTitleCase(v.delivery_mode),
              ]),
            ];
          }
          setquickorder(temp);
        } else {
          setquickorder([]);
        }
      })
      .catch((err) => {
        alert(`Error Occurred in Get 3: ${err}`);
      });
  };

  useEffect(() => {
    getquickorders();
  }, []);

  const handleClientAction = () => {
    navigate("/master/clients/add_clients");
  };

  useEffect(() => {
    if (consignordata) {
      const selectedParty = consignordata.find((v) => v.id === consignor_id);

      if (selectedParty) {
        const {
          id,
          name,
          address_line1,
          address_line2,
          phone,
          pincode_name,
          pincode,
          state,
          city,
          id_type,
          id_proof,
          city_name,
          state_name,
          client_rate,
        } = selectedParty;

        const mappedClientRateNew = client_rate?.map((rate) => ({
          client_id: rate.client_id,
          client_name: rate.client_name,
          quot_date: rate.quot_date,
          quotation_details: rate.quotation_details.map((detail) => ({
            id: detail.id,
            origin_city_name: detail.origin_city_name,
            destination_city_name: detail.destination_city_name,
            packing_type_name: detail.packing_type_name,
            item_type_name: detail.item_type_name,
            // Add more properties if needed
          })),
        }));

        // setPartyDetails({
        //   id,
        //   name,
        //   address1: address_line1,
        //   address1S: address_line2,
        //   phone,
        //   pincode: pincode_name,
        //   pincode_id: pincode,
        //   state,
        //   city,
        //   id_type,
        //   id_proof,
        //   city_name,
        //   state_name,
        //   client_rate_new: mappedClientRateNew,
        // });
      }
    }
  }, [consignor_id, consignordata]);

  useEffect(() => {
    if (consignordataidwise) {
      const selectedPartyid = consignordataidwise.find(
        (v) => v.id_proof === type_identity_consignor_no
      );
      if (selectedPartyid) {
        const {
          id,
          name,
          address_line1,
          address_line2,
          phone,
          pincode_name,
          pincode,
          id_type,
          id_proof,
          city_name,
          state_name,
          client_rate,
        } = selectedPartyid;

        const mappedClientRateNew = client_rate?.map((rate) => ({
          client_id: rate.client_id,
          client_name: rate.client_name,
          quot_date: rate.quot_date,
          quotation_details: rate.quotation_details.map((detail) => ({
            id: detail.id,
            origin_city_name: detail.origin_city_name,
            destination_city_name: detail.destination_city_name,
            packing_type_name: detail.packing_type_name,
            item_type_name: detail.item_type_name,
            // Add more properties if needed
          })),
        }));

        setPartyDetailsIdwise({
          id,
          name,
          address1: address_line1,
          address1S: address_line2,
          phone,
          pincode: pincode_name,
          pincode_id: pincode,
          id_type,
          id_proof,
          city_name,
          state_name,
          client_rate_new: mappedClientRateNew,
        });
      }
    }
  }, [type_identity_consignor_no, consignordataidwise]);

  useEffect(() => {
    if (consigneedata) {
      const selectedParty1 = consigneedata.find((v) => v.id === consignee_id);

      if (selectedParty1) {
        const {
          id,
          name,
          address_line1,
          address_line2,
          phone,
          pincode_name,
          pincode,
          state,
          city,
          id_type,
          id_proof,
          city_name,
          state_name,
        } = selectedParty1;

        // setPartyDetails2({
        //   id,
        //   name,
        //   address2: address_line1,
        //   address2C: address_line2,
        //   phone,
        //   pincode: pincode_name,
        //   pincode_id: pincode,
        //   state,
        //   city,
        //   id_type: id_type,
        //   id_proof: id_proof,
        //   city_name: city_name,
        //   state_name: state_name,
        // });
      }
    }
  }, [consignee_id, consigneedata]);

  useEffect(() => {
    getBranches("from");
  }, [from_branch_search, from_branch_page]);

  useEffect(() => {
    getBranches("to");
  }, [to_branch_search, to_branch_page]);

  useEffect(() => {
    getFreightCities();
  }, [city_search_item_f, city_page_f]);

  useEffect(() => {
    if (location.state === null) {
      setorigincity("");
      setorigincity_id("");
    }
  }, [from_branch_id]);

  useEffect(() => {
    if (location.state === null) {
      setdestinationcity("");
      setdestinationcity_id("");
    }
  }, [to_branch_id]);

  useEffect(() => {
    if (!origincity) {
      setconsignor_list([]);
      setconsignordata([]);
    }
  }, [origincity]);

  useEffect(() => {
    if (!destinationcity) {
      setconsignee_list([]);
      setconsigneedata([]);
    }
  }, [destinationcity]);

  useEffect(() => {
    if (state !== "" && togstate && !by_pincode) {
      setconsignor_city("");
      setcity_list_s([]);
      setpincode("");
      setpincode_list_s([]);
    }
  }, [state]);

  useEffect(() => {
    if (consginee_st !== "" && togstate_c && !by_pincode_f_c) {
      setconsginee_c("");
      setcity_list__c([]);
      setconsignee_pincode("");
      setpincode_list_f_c([]);
    }
  }, [consginee_st]);

  useEffect(() => {
    if (isupdating) {
      settogstate(false);
      settogcity(false);
      settogpincode(false);
    }
  }, []);

  useEffect(() => {
    if (isupdating) {
      settogstate_c(false);
      settogcity_c(false);
      settogpincode_c(false);
    }
  }, []);

  useEffect(() => {
    if (consginee_c !== "" && togcity_c && !by_pincode_f_c) {
      setconsignee_pincode("");
      setpincode_list_f_c([]);
    }
  }, [consginee_c]);

  useEffect(() => {
    if (city !== "" && togcity && !by_pincode) {
      setpincode("");
      setpincode_list_s([]);
    }
  }, [city]);

  // history Client Booking
  const handlClk = () => {
    navigate("/booking/clientBooking/ClientBookingHistoryPage", {
      state: { order: Booking_data },
    });
  };

  useEffect(() => {
    if (location.state === null) {
      settype_identity_consignor_no("");
    }
  }, [type_identity_consignor]);

  useEffect(() => {
    if (location.state === null) {
      settype_identity_consignee_no("");
    }
  }, [type_identity_consignee]);

  useEffect(() => {
    if (invoice_list?.length > 0) {
      let invdata = invoice_list.reduce((acc, current_value) => {
        return (
          parseFloat(acc ?? 0) +
          parseFloat(current_value["invoice_amount"] || 0)
        );
      }, 0);

      let fieldsAreValid = true;

      let is_eway = invoice_list.some((v) => v["ewaybill_no"]);
      let is_invalid_eway = invoice_list.some(
        (v) => v["ewaybill_no"].length !== 0 && v["ewaybill_no"].length !== 12
      );
      // Check if any invoice has partially filled fields and set error accordingly
      invoice_list.forEach((invoice) => {
        const { invoice_at, invoice_no, invoice_amount } = invoice;
        const isAnyFieldFilled =
          invoice_at !== "" ||
          invoice_no !== "" ||
          invoice_amount !== "" ||
          ewaybill_no !== "";

        if (isAnyFieldFilled) {
          if (invoice_at === "" || invoice_no === "" || invoice_amount === "") {
            fieldsAreValid = false;
          }
        }
      });
      if (is_invalid_eway) {
        setinvoice_error(true);
        setinvoice_err_text("Eway Bill number must have 12 digits.");
      } else if (invdata > 50000 && !is_eway && is_found) {
        setinvoice_error(true);
        setinvoice_err_text("At least one Eway Bill number is required.");
      } else if (!fieldsAreValid) {
        setinvoice_error(true);
        setinvoice_err_text(
          "If any of the invoice fields are filled, all must be filled."
        );
      } else {
        setinvoice_error(false);
        setinvoice_err_text("");
      }
    }
  }, [invoice_list]);

  useEffect(() => {
    if (row1?.length > 0) {
      let fieldsAreValid = true;

      // Check if any invoice has partially filled fields and set error accordingly
      row1.forEach((invoice) => {
        const isAnyFieldFilled =
          invoice[0][0] !== "" ||
          invoice[1][0] !== "" ||
          invoice[2] !== "" ||
          invoice[3] !== "" ||
          invoice[4] !== "";

        if (isAnyFieldFilled) {
          if (
            invoice[0][0] === "" ||
            invoice[1][0] === "" ||
            invoice[2] === "" ||
            invoice[3] === "" ||
            invoice[4] === ""
          ) {
            fieldsAreValid = false;
          }
        }
      });

      if (!fieldsAreValid) {
        setpkg_error(true);
        setpkg_err_text(
          "If any of the Packages fields are filled, all must be filled."
        );
      } else {
        setpkg_error(false);
        setpkg_err_text("");
      }
    }
  }, [row1, refresh_row]);

  return (
    <form
      style={{ background: "#eafcf5" }}
      onSubmit={(e) => {
        let shaw = Object.entries(validation.values);
        let filter_value = shaw.filter((v) => v[1] == "" || v[1] == 0);
        e.preventDefault();

        validation.handleSubmit(e.values);
        return false;
      }}
      encType="multipart/form-data"
    > 
      <div>
        <PageTitle
          page={isupdating ? "Update Client Order" : "Add Client Booking"}
        />

        <div
          className="mb-1 main-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{isupdating ? "Update Client Order" : "Add Client Booking"}</div>
          {isupdating ? (
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                handlClk();
              }}
            >
              History
            </Button>
          ) : null}
        </div>
      </div>
      <div className="m-1">
        <Col lg={12}>
          <Card className="shadow bg-white rounded">
            <CardTitle className="mb-1 header">
              <div className="header-text-icon header-text">
                Booking Info
                <IconContext.Provider
                  value={{
                    className: "header-add-icon",
                  }}
                >
                  <div onClick={toggle_circle}>
                    {circle_btn ? (
                      <MdRemoveCircleOutline />
                    ) : (
                      <MdAddCircleOutline />
                    )}
                  </div>
                </IconContext.Provider>
              </div>
            </CardTitle>

            {circle_btn ? (
              <CardBody>
                {/* Booking Info */}
                <Row id="booking_info">
                  <Col lg={3} md={6} sm={6}>
                    <div className="mb-2">
                      <Label className="header-child text-color">
                        Entry Type
                      </Label>
                      <Row>
                        <Col md={6} sm={6}>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="entry_type"
                              id="exampleRadios3"
                              value="MANUALLY"
                              // disabled={isupdating ? entry_type_btn : ""}
                              disabled={entry_type_btn}  // only Auto Generate is required.
                              onClick={() => {
                                setentry_type_btn("MANUALLY");
                              }}
                              checked={entry_type_btn === "MANUALLY"}
                            />
                            <label
                              className="form-check-label input-box"
                              htmlFor="exampleRadios2"
                            >
                              Manually
                            </label>
                          </div>
                        </Col>
                        <Col md={6} sm={6}>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="entry_type"
                              id="exampleRadios4"
                              value="AUTO GENERATE"
                              disabled={isupdating ? entry_type_btn : ""}
                              onClick={() => {
                                setentry_type_btn("AUTO GENERATE");
                              }}
                              checked={entry_type_btn === "AUTO GENERATE"}
                            />

                            <label
                              className="form-check-label input-box"
                              htmlFor="exampleRadios1"
                            style={{whiteSpace:"nowrap"}}
                            >
                              Auto Generate
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {entry_type_btn === "MANUALLY" ? (
                    <Col lg={3} md={6} sm={6}>
                      <div className="mb-2">
                        <Label className="header-child">GR. Number</Label>
                        <span className="mandatory"> *</span>
                        <Input
                          min={0}
                          value={gr_no_value}
                          disabled={isupdating ? gr_no_value : ""}
                          onBlur={() => {
                            if (gr_no_value === "") {
                              setgr_no_value_error(true);
                            }
                          }}
                          onChange={(event) => {
                            const { value } = event.target;
                            if (value.length <= 10 || value.length <= 6) {
                              setgr_no_value(value);
                            }
                          }}
                          onFocus={() => {
                            setclicked(true);
                          }}
                          type="number"
                          name="gr_no"
                          className="form-control-md"
                          id="input"
                          placeholder="Enter GR. Number"
                          invalid={gr_no_value_error}
                        />
                        {gr_no_value_error ? (
                          <FormFeedback type="invalid">
                            Enter GR Number
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  ) : null}
                  {/* Field */}
                  {entry_type_btn === "AUTO GENERATE" &&
                  location.state !== null ? (
                    <Col lg={3} md={6} sm={6}>
                      <div className="mb-2">
                        <Label className="header-child">GR. No</Label>
                        <span className="mandatory"> *</span>
                        <Input
                          // onBlur={validation.handleBlur}
                          value={isupdating ? gr_no_value : ""}
                          type="text"
                          id="input"
                          label="First Name"
                          // name="docket_no"
                          className="form-control-md"
                          placeholder="Auto Generate"
                          disabled
                        />
                      </div>
                    </Col>
                  ) : null}

                  <Col lg={3} md={6} sm={6}>
                    <div className="mb-2">
                      <Label className="header-child">Customer Type </Label>
                      <span className="mandatory"> *</span>
                      <NSearchInput
                        data_list={ctype_list}
                        data_item_s={ctype}
                        set_data_item_s={setctype}
                        error_message="Select Any Customer Type"
                        // set_id={setclient_id}
                        disable_me={true}
                      />
                    </div>
                  </Col>

                  <Col lg={3} md={6} sm={6}>
                    <div className="mb-2">
                      <Label className="header-child">Booking At</Label>
                      <div>
                        <input
                          type="datetime-local"
                          id="input"
                          className="form-control d-block form-control-md "
                          max={new Date(Date.now() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 16)} // Max in IST
                          min={new Date(new Date().setDate(new Date().getDate() - 7) + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 16)} // Min in IST
                          value={booking_date}
                          onChange={(val) => {
                            setbooking_date(val.target.value);
                          }}
                          disabled={isupdating}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={3} md={6} sm={6}>
                    <div className="mb-3">
                      <Label className="header-child sm-10">
                        From Branch <span className="mandatory"> *</span>
                      </Label>
                      <SearchInput
                        data_list={from_branch_list}
                        setdata_list={setfrom_branch_list}
                        data_item_s={from_branch}
                        set_data_item_s={setfrom_branch}
                        set_id={setfrom_branch_id}
                        page={from_branch_page}
                        setpage={setfrom_branch_page}
                        setsearch_item={setfrom_branch_search}
                        error_message={"From Branch Is Required"}
                        error_s={from_branch_error}
                        loaded={from_branch_loaded}
                        count={from_branch_count}
                        bottom={from_branch_bottom}
                        setbottom={setfrom_branch_bottom}
                        disable_me={true}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={6}>
                    <div className="mb-3">
                      <Label className="header-child sm-10">
                        To Branch <span className="mandatory"> *</span>
                      </Label>
                      <SearchInput
                        data_list={to_branch_list}
                        setdata_list={setto_branch_list}
                        data_item_s={to_branch}
                        set_data_item_s={setto_branch}
                        set_id={setto_branch_id}
                        page={to_branch_page}
                        setpage={setto_branch_page}
                        setsearch_item={setto_branch_search}
                        error_message={"Select To Branch Name"}
                        error_s={to_branch_error}
                        loaded={to_branch_loaded}
                        count={to_branch_count}
                        bottom={to_branch_bottom}
                        setbottom={setto_branch_bottom}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            ) : null}
          </Card>
        </Col>
      </div>

      {ctype && (
        <div className="m-1">
          <Row>
            <Col lg={6}>
              <Card className="shadow bg-white rounded">
                <CardTitle className="mb-1 header">
                  <div className="header-text-icon header-text">
                    Consignor Info
                    <IconContext.Provider
                      value={{
                        className: "header-add-icon",
                      }}
                    >
                      <div onClick={toggle_circle1}>
                        {circle_btn1 ? (
                          <MdRemoveCircleOutline />
                        ) : (
                          <MdAddCircleOutline />
                        )}
                      </div>
                    </IconContext.Provider>
                  </div>
                </CardTitle>
                {circle_btn1 ? (
                  <CardBody>
                    <Row id="consinor_info">
                      <>
                        {ctype == "CONTRACTUAL" && (
                          <>
                            {/* <Col lg={5} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Consignor Name
                                </Label>
                                <span className="mandatory"> *</span>
                                <SearchInput
                                  data_list={consignor_list}
                                  setdata_list={setconsignor_list}
                                  data_item_s={consignor_name}
                                  set_data_item_s={setconsignor_name}
                                  set_id={setconsignor_id}
                                  page={consignor_page}
                                  setpage={setconsignor_page}
                                  setsearch_item={setsearch_consignor}
                                  error_message={"Select Consignor Name"}
                                  error_s={consignor_error}
                                  loaded={consignor_loaded}
                                  count={consignor_count}
                                  bottom={consignor_bottom}
                                  setbottom={setconsignor_bottom}
                                />
                              </div>
                            </Col> */}
                            <Col lg={5} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Consignor Name
                                </Label>
                                <Input
                                  value={toTitleCase(consignor_name)}
                                  className="form-control d-block from control-md"
                                  bsSize="sm"
                                  type="text"
                                  id="input"
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col lg={3} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">DOC Type</Label>
                                <Input
                                  value={toTitleCase(partyDetails?.id_type)}
                                  className="form-control d-block from control-md"
                                  bsSize="sm"
                                  type="text"
                                  id="input"
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">DOC No</Label>
                                <Input
                                  value={partyDetails?.id_proof}
                                  className="form-control d-block from control-md"
                                  bsSize="sm"
                                  type="text"
                                  id="input"
                                  disabled
                                />
                              </div>
                            </Col>

                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Phone Number
                                </Label>
                                <Input
                                  value={partyDetails?.phone}
                                  className="form-control d-block from control-md"
                                  bsSize="sm"
                                  type="number"
                                  id="input"
                                  disabled
                                />
                              </div>
                            </Col>

                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Address Line 1
                                </Label>
                                <Input
                                  value={toTitleCase(
                                    partyDetails?.address_line1
                                  )}
                                  className="form-control d-block from control-md"
                                  bsSize="sm"
                                  type="text"
                                  id="input"
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Address Line 2
                                </Label>
                                <Input
                                  value={toTitleCase(
                                    partyDetails?.address_line1
                                  )}
                                  className="form-control d-block from control-md"
                                  bsSize="sm"
                                  type="text"
                                  id="input"
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">State</Label>
                                <span onClick={() => setby_pincode(false)}>
                                  <Input
                                    value={toTitleCase(
                                      partyDetails?.state_name
                                    )}
                                    className="form-control d-block from control-md"
                                    bsSize="sm"
                                    type="text"
                                    id="input"
                                    disabled
                                  />
                                </span>
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">City</Label>
                                <Input
                                  value={toTitleCase(partyDetails?.city_name)}
                                  className="form-control d-block from control-md"
                                  bsSize="sm"
                                  type="text"
                                  id="input"
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">Pin Code</Label>
                                <Input
                                  value={partyDetails?.pincode_name}
                                  className="form-control d-block from control-md"
                                  bsSize="sm"
                                  type="number"
                                  id="input"
                                  disabled
                                />
                              </div>
                            </Col>
                          </>
                        )}

                        {/* {ctype == "WALKING" && (
                          <>
                            <Col lg={5} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Consignor Name
                                </Label>
                                <span className="mandatory"> *</span>
                                <Input
                                  value={consignor_name}
                                  onBlur={() => {
                                    if (consignor_name === "") {
                                      setconsignor_error(true);
                                    }
                                  }}
                                  onChange={(val) => {
                                    setconsignor_name(val.target.value);
                                  }}
                                  type="text"
                                  id="input"
                                  invalid={consignor_error}
                                  className="form-control-md"
                                  placeholder="Enter Consignor Name"
                                />
                                {consignor_error && (
                                  <FormFeedback type="invalid">
                                    Consignor is required
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col lg={3} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  DOC Type<span className="mandatory"> *</span>
                                </Label>
                                <NSearchInput
                                  data_list={type_identity_list}
                                  data_item_s={type_identity_consignor}
                                  set_data_item_s={settype_identity_consignor}
                                  show_search={false}
                                />
                              </div>
                            </Col>

                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  DOC No<span className="mandatory"> *</span>
                                </Label>

                                <Input
                                  type="text"
                                  className="form-control-md"
                                  id="input"
                                  name="I.D"
                                  placeholder="Enter Doc. Number"
                                  value={toTitleCase(
                                    type_identity_consignor_no
                                  ).toUpperCase()}
                                  onBlur={() => {
                                    if (type_identity_consignor_no === "") {
                                      setconsignor_doc_error(true);
                                    }
                                  }}
                                  onChange={(event) => {
                                    const { value } = event.target;
                                    if (type_identity_consignor === "GSTIN") {
                                      if (value.length <= 15) {
                                        settype_identity_consignor_no(value);
                                      }
                                    } else if (
                                      type_identity_consignor === "AADHAR"
                                    ) {
                                      if (value.length <= 12) {
                                        settype_identity_consignor_no(value);
                                      }
                                    } else {
                                      if (value.length <= 10) {
                                        settype_identity_consignor_no(value);
                                      }
                                    }
                                  }}
                                  invalid={consignor_doc_error}
                                />
                                {consignor_doc_error && (
                                  <FormFeedback type="invalid">
                                    Consignor ID is required
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>

                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Phone Number
                                  <span className="mandatory"> *</span>
                                </Label>
                                <Input
                                  value={consignor_phone_no}
                                  onBlur={() => {
                                    if (consignor_phone_no === "") {
                                      setconsignor_phone_no_error(true);
                                    }
                                  }}
                                  onChange={(event) => {
                                    const { value } = event.target;
                                    if (value.length <= 10) {
                                      setconsignor_phone_no(value);
                                    }
                                  }}
                                  id="input"
                                  type="number"
                                  className="form-control-md"
                                  placeholder="Enter Phone Number"
                                  invalid={consignor_phone_no_error}
                                />
                                {consignor_phone_no_error ? (
                                  <FormFeedback type="invalid">
                                    Phone Number is Required
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Address Line 1
                                  <span className="mandatory"> *</span>
                                </Label>
                                <Input
                                  value={consignor_add_1}
                                  onBlur={() => {
                                    if (consignor_add_1 === "") {
                                      setconsignor_add_1_error(true);
                                    }
                                  }}
                                  onChange={(val) => {
                                    setconsignor_add_1(val.target.value);
                                  }}
                                  id="input"
                                  type="text"
                                  className="form-control-md"
                                  placeholder="Enter Address Line 1"
                                  invalid={consignor_add_1_error}
                                />
                                {consignor_add_1_error ? (
                                  <FormFeedback type="invalid">
                                    Address Line 1 is Required
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Address Line 2
                                </Label>
                                <Input
                                  value={consignor_add_2}
                                  onChange={(val) => {
                                    setconsignor_add_2(val.target.value);
                                  }}
                                  id="input"
                                  type="text"
                                  className="form-control-md"
                                  placeholder="Enter Address Line 2"
                                />
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  State<span className="mandatory"> *</span>
                                </Label>

                                <span onClick={() => setby_pincode(false)}>
                                  <SearchInput
                                    data_list={state_list_s}
                                    setdata_list={setstate_list_s}
                                    data_item_s={state}
                                    set_data_item_s={setstate}
                                    set_id={setstate_id}
                                    page={state_page}
                                    setpage={setstate_page}
                                    error_message={"Select Any State"}
                                    error_s={state_error}
                                    search_item={state_search_item}
                                    setsearch_item={setstate_search_item}
                                  />
                                </span>
                                <div className="mt-1 error-text" color="danger">
                                  {state_error ? "State is Required" : null}
                                </div>
                              </div>
                            </Col>

                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">City</Label>
                                <span className="mandatory"> *</span>
                                <SearchInput
                                  data_list={city_list_s}
                                  setdata_list={setcity_list_s}
                                  data_item_s={consignor_city}
                                  set_data_item_s={setconsignor_city}
                                  set_id={setcity_id}
                                  page={city_page}
                                  setpage={setcity_page}
                                  error_message={"Select Any City"}
                                  error_s={city_error}
                                  search_item={city_search_item}
                                  setsearch_item={setcity_search_item}
                                />
                                <div className="mt-1 error-text" color="danger">
                                  {city_error ? "City is required" : null}
                                </div>
                              </div>
                            </Col>

                            <Col lg={4} md={6} sm={6}>
                              {pincode_loaded ? (
                                <div className="mb-2">
                                  <Label className="header-child">
                                    Pin Code
                                    <span className="mandatory"> *</span>
                                  </Label>

                                  <SearchInput
                                    data_list={pincode_list_s}
                                    setdata_list={setpincode_list_s}
                                    data_item_s={pincode}
                                    set_data_item_s={setpincode}
                                    set_id={setpincode_id}
                                    page={pincode_page}
                                    setpage={setpincode_page}
                                    search_item={pincode_search_item}
                                    setsearch_item={setpincode_search_item}
                                    error_message={"Select Any Pin Code"}
                                    error_s={pincode_list_error}
                                  />
                                  <div
                                    className="mt-1 error-text"
                                    color="danger"
                                  >
                                    {pin_code_error
                                      ? "Pin Code is required"
                                      : null}
                                  </div>
                                </div>
                              ) : (
                                <div className="mb-2">
                                  <Label className="header-child">
                                    Pin Code
                                    <span className="mandatory"> *</span>
                                  </Label>

                                  <Input
                                    onChange={(val) => {
                                      setpincode(val.target.value);
                                      if (val.target.value.length != 0) {
                                        setpincode_error(false);
                                        if (val.target.value.length == 6) {
                                          setpincode_error2(false);
                                        } else {
                                          setpincode_error2(true);
                                        }
                                      } else {
                                        setpincode_error(true);
                                      }
                                    }}
                                    onBlur={() => {
                                      if (pincode.length == 0) {
                                        setpincode_error(true);
                                      } else {
                                        if (pincode.length != 6) {
                                          setpincode_error(false);
                                          setpincode_error2(true);
                                        } else {
                                          getPincode(
                                            pincode,
                                            "pincode",
                                            "Shipper"
                                          );
                                          setpincode_error2(false);
                                          setby_pincode(true);
                                        }
                                      }
                                    }}
                                    value={pincode}
                                    invalid={pincode_error || pincode_error2}
                                    type="number"
                                    id="input"
                                    className="form-control-md"
                                    name="pincode"
                                    placeholder="Enter Pin Code"
                                  />

                                  {pincode_loaded == false &&
                                  pincode_error == true ? (
                                    <div style={{ color: "red" }}>
                                      Enter Pin Code
                                    </div>
                                  ) : null}

                                  {pincode_loaded == false &&
                                  pincode_error == false &&
                                  pincode_error2 == true ? (
                                    <div style={{ color: "red" }}>
                                      Pin Code should 6 digit
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </Col>
                          </>
                        )} */}
                      </>
                      <div>
                        {user.application_use_type === "Client User" &&
                          user.is_superuser && (
                            <span
                              className="link-text"
                              onClick={handleClientAction}
                            >
                              <IconContext.Provider
                                value={{
                                  className: "icon",
                                }}
                              >
                                <MdAdd />
                              </IconContext.Provider>
                              Add New Consignor
                            </span>
                          )}
                      </div>
                    </Row>
                  </CardBody>
                ) : null}
              </Card>
            </Col>
            <Col lg={6} id="consignee_info">
              <Card className="shadow bg-white rounded">
                <CardTitle className="mb-1 header">
                  <div className="header-text-icon header-text">
                    Consignee Info
                    <IconContext.Provider
                      value={{
                        className: "header-add-icon",
                      }}
                    >
                      <div onClick={toggle_circle2}>
                        {circle_btn2 ? (
                          <MdRemoveCircleOutline />
                        ) : (
                          <MdAddCircleOutline />
                        )}
                      </div>
                    </IconContext.Provider>
                  </div>
                </CardTitle>
                {circle_btn2 ? (
                  <CardBody>
                    <Row>
                      <>
                        {ctype == "CONTRACTUAL" && (
                          <>
                            <Col lg={5} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Consignee Name
                                </Label>
                                <span className="mandatory"> *</span>
                                <SearchInput
                                  data_list={consignee_list}
                                  setdata_list={setconsignee_list}
                                  data_item_s={consignee_name}
                                  set_data_item_s={setconsignee_name}
                                  set_id={setconsignee_id}
                                  page={consignee_page}
                                  setpage={setconsignee_page}
                                  setsearch_item={setsearch_consignee}
                                  error_message={"Select Consignee Name"}
                                  error_s={consignee_error}
                                  loaded={consignee_loaded}
                                  count={consignee_count}
                                  bottom={consignee_bottom}
                                  setbottom={setconsignee_bottom}
                                />
                              </div>
                            </Col>
                            {partyDetails2 && (
                              <>
                                <Col lg={3} md={6} sm={6}>
                                  <div className="mb-2">
                                    <Label className="header-child">
                                      DOC Type
                                    </Label>
                                    <Input
                                      value={toTitleCase(partyDetails2.id_type)}
                                      className="form-control d-block from control-md"
                                      bsSize="sm"
                                      type="text"
                                      id="input"
                                      disabled
                                    />
                                  </div>
                                </Col>
                                {type_identity_consignee && (
                                  <>
                                    <Col lg={4} md={6} sm={6}>
                                      <div className="mb-2">
                                        <Label className="header-child">
                                          DOC No
                                        </Label>
                                        <Input
                                          value={partyDetails2.id_proof}
                                          className="form-control d-block from control-md"
                                          bsSize="sm"
                                          type="text"
                                          id="input"
                                          disabled
                                        />
                                      </div>
                                    </Col>
                                  </>
                                )}
                                <Col lg={4} md="6" sm="6">
                                  <div className="mb-2">
                                    <Label className="header-child">
                                      Phone Number
                                    </Label>
                                    <Input
                                      value={partyDetails2.phone}
                                      className="form-control d-block from control-md"
                                      bsSize="sm"
                                      type="text"
                                      id="input"
                                      disabled
                                    />

                                    <div
                                      className="mt-1 error-text"
                                      color="danger"
                                    >
                                      {consignee_phone_number_error
                                        ? "Phone number is Required"
                                        : null}
                                      {phon_error
                                        ? "Phone number must be 10 digit"
                                        : null}
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={4} md={6} sm={6}>
                                  <div className="mb-2">
                                    <Label className="header-child sm-10">
                                      Address Line 1
                                    </Label>
                                    <Input
                                      value={toTitleCase(
                                        partyDetails2.address_line1
                                      )}
                                      className="form-control d-block from control-md"
                                      bsSize="sm"
                                      type="text"
                                      id="input"
                                      disabled
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4} md={6} sm={6}>
                                  <div className="mb-2">
                                    <Label className="header-child sm-10">
                                      Address Line 2
                                    </Label>
                                    <Input
                                      value={toTitleCase(
                                        partyDetails2.address_line1
                                      )}
                                      className="form-control d-block from control-md"
                                      bsSize="sm"
                                      type="text"
                                      id="input"
                                      disabled
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4} md={6} sm={6}>
                                  <div className="mb-2">
                                    <Label className="header-child sm-10">
                                      state
                                    </Label>
                                    <Input
                                      value={toTitleCase(
                                        partyDetails2.state_name
                                      )}
                                      className="form-control d-block from control-md"
                                      bsSize="sm"
                                      type="text"
                                      id="input"
                                      disabled
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4} md={6} sm={6}>
                                  <div className="mb-2">
                                    <Label className="header-child sm-10">
                                      City
                                    </Label>
                                    <Input
                                      value={toTitleCase(
                                        partyDetails2.city_name
                                      )}
                                      className="form-control d-block from control-md"
                                      bsSize="sm"
                                      type="text"
                                      id="input"
                                      disabled
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4} md={6} sm={6}>
                                  <div className="mb-3">
                                    <Label className="header-child">
                                      Pin Code
                                    </Label>
                                    <Input
                                      value={partyDetails2.pincode_name}
                                      className="form-control d-block from control-md"
                                      bsSize="sm"
                                      type="text"
                                      id="input"
                                      disabled
                                    />
                                  </div>
                                </Col>
                              </>
                            )}
                          </>
                        )}

                        {ctype == "WALKING" && (
                          <>
                            <Col lg={5} md="6" sm="6">
                              <div className="mb-2">
                                <Label className="header-child">
                                  Consignee Name
                                </Label>
                                <span className="mandatory"> *</span>
                                <Input
                                  value={consignee_name}
                                  onBlur={() => {
                                    if (consignee_name === "") {
                                      setconsignee_error(true);
                                    }
                                  }}
                                  onChange={(val) => {
                                    setconsignee_name(val.target.value);
                                  }}
                                  type="text"
                                  id="input"
                                  className="form-control-md"
                                  placeholder="Enter Consignee Name"
                                  invalid={consignee_error}
                                />
                                {consignee_error ? (
                                  <FormFeedback type="invalid">
                                    Consignee Name is Required
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg={3} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  DOC Type<span className="mandatory"> *</span>
                                </Label>
                                <NSearchInput
                                  data_list={type_identity_list}
                                  data_item_s={type_identity_consignee}
                                  set_data_item_s={settype_identity_consignee}
                                  show_search={false}
                                />
                              </div>
                            </Col>

                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  DOC No<span className="mandatory"> *</span>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control-md"
                                  id="input"
                                  name="I.D"
                                  placeholder="Enter Document Number "
                                  invalid={type_identity_consignee_no_error}
                                  value={toTitleCase(
                                    type_identity_consignee_no
                                  ).toUpperCase()}
                                  onBlur={() => {
                                    if (type_identity_consignee_no === "") {
                                      settype_identity_consignee_no_error(true);
                                    }
                                  }}
                                  onChange={(event) => {
                                    const { value } = event.target;
                                    if (type_identity_consignee === "GSTIN") {
                                      if (value.length <= 15) {
                                        settype_identity_consignee_no(value);
                                      }
                                    } else if (
                                      type_identity_consignee === "AADHAR"
                                    ) {
                                      if (value.length <= 12) {
                                        settype_identity_consignee_no(value);
                                      }
                                    } else {
                                      if (value.length <= 10) {
                                        settype_identity_consignee_no(value);
                                      }
                                    }
                                  }}
                                />
                                {settype_identity_consignee_no_error ? (
                                  <FormFeedback type="invalid">
                                    Document Number is Required
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg={4} md="6" sm="6">
                              <div className="mb-2">
                                <Label className="header-child">
                                  Phone Number
                                </Label>
                                <span className="mandatory"> *</span>
                                <Input
                                  value={consignee_phone_number}
                                  onBlur={() => {
                                    if (consignee_phone_number === "") {
                                      setconsignee_phone_number_error(true);
                                    }
                                  }}
                                  onChange={(event) => {
                                    const { value } = event.target;
                                    if (value.length <= 10) {
                                      setconsignee_phone_number(value);
                                    }
                                  }}
                                  type="number"
                                  id="input"
                                  className="form-control-md"
                                  placeholder="Enter Phone Number"
                                  invalid={consignee_phone_number_error}
                                />
                                {consignee_phone_number_error ? (
                                  <FormFeedback type="invalid">
                                    Phone Number is Required
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Address Line 1
                                  <span className="mandatory"> *</span>
                                </Label>

                                <Input
                                  value={consignee_address_line1}
                                  onBlur={() => {
                                    if (consignee_address_line1 === "") {
                                      setconsignee_address_line1_error(true);
                                    }
                                  }}
                                  onChange={(val) => {
                                    setconsignee_address_line1(
                                      val.target.value
                                    );
                                  }}
                                  type="text"
                                  id="input"
                                  className="form-control-md"
                                  placeholder="Enter Address Line 1"
                                  invalid={consignee_address_line1_error}
                                />
                                {consignee_address_line1_error ? (
                                  <FormFeedback type="invalid">
                                    Address Line 1 is Required
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">
                                  Address Line 2
                                </Label>
                                <Input
                                  value={consignee_address_line2}
                                  onChange={(val) => {
                                    setconsignee_address_line2(
                                      val.target.value
                                    );
                                  }}
                                  type="text"
                                  id="input"
                                  className="form-control-md"
                                  placeholder="Enter Address Line 2"
                                />
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">State</Label>
                                <span className="mandatory"> *</span>
                                <span onClick={() => setby_pincode_c(false)}>
                                  <SearchInput
                                    data_list={state_list_c}
                                    setdata_list={setstate_list_c}
                                    data_item_s={consginee_st}
                                    set_data_item_s={setconsginee_st}
                                    set_id={setstate_id_f_c}
                                    page={state_page_c}
                                    setpage={setstate_page_c}
                                    error_message={"Select Any State"}
                                    error_s={state_error}
                                    search_item={state_search_item_c}
                                    setsearch_item={setstate_search_item_c}
                                  />
                                </span>
                                <div className="mt-1 error-text" color="danger">
                                  {state_error ? "State is Required" : null}
                                </div>
                              </div>
                            </Col>

                            <Col lg={4} md={6} sm={6}>
                              <div className="mb-2">
                                <Label className="header-child">City</Label>
                                <span className="mandatory"> *</span>
                                <SearchInput
                                  data_list={city_list__c}
                                  setdata_list={setcity_list__c}
                                  data_item_s={consginee_c}
                                  set_data_item_s={setconsginee_c}
                                  set_id={setcity_id_c}
                                  page={city_page_c}
                                  setpage={setcity_page_c}
                                  error_message={"Select Any City"}
                                  error_s={city_error_c}
                                  search_item={city_search_item_c}
                                  setsearch_item={setcity_search_item_c}
                                  loaded={cityc_loaded}
                                  count={cityc_count}
                                  bottom={cityc_bottom}
                                  setbottom={setcityc_bottom}
                                />
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={6}>
                              {pincode_loaded_f_c ? (
                                <div className="mb-2">
                                  <Label className="header-child">
                                    Pin Code
                                  </Label>
                                  <SearchInput
                                    data_list={pincode_list_f_c}
                                    setdata_list={setpincode_list_f_c}
                                    data_item_s={consignee_pincode}
                                    set_data_item_s={setconsignee_pincode}
                                    set_id={setconsignee_p_id}
                                    page={pincode_page_c}
                                    setpage={setpincode_page_c}
                                    search_item={pincode_search_item_c}
                                    setsearch_item={setpincode_search_item_c}
                                    error_message={"Select Any Pincode"}
                                    error_s={pincode_list_error_c}
                                    loaded={loadc_pincode}
                                    count={pincodec_count}
                                    bottom={pincodec_bottom}
                                    setbottom={setpincodec_bottom}
                                  />
                                  <div
                                    className="mt-1 error-text"
                                    color="danger"
                                  >
                                    {pin_code_error
                                      ? "Pin Code is required"
                                      : null}
                                  </div>
                                </div>
                              ) : (
                                <div className="mb-2">
                                  <Label className="header-child">
                                    Pin Code
                                  </Label>
                                  <span className="mandatory"> *</span>
                                  <Input
                                    onChange={(val) => {
                                      setpincode_c(val.target.value);
                                      if (val.target.value.length !== 0) {
                                        setpincode_error_f_c(false);
                                        if (val.target.value.length === 6) {
                                          setpincode_error2_f_c(false);
                                        } else {
                                          setpincode_error2_f_c(true);
                                        }
                                      } else {
                                        setpincode_error_f_c(true);
                                      }
                                    }}
                                    onBlur={() => {
                                      if (pincode_c.length === 0) {
                                        setpincode_error_f_c(true);
                                      } else {
                                        if (pincode_c.length !== 6) {
                                          setpincode_error_f_c(false);
                                          setpincode_error2_f_c(true);
                                        } else {
                                          getPincode(
                                            pincode_c,
                                            "pincode",
                                            "Consignee"
                                          );
                                          setpincode_error2_f_c(false);
                                          setby_pincode_f_c(true);
                                        }
                                      }
                                    }}
                                    value={pincode_c}
                                    invalid={
                                      pincode_error_f_c || pincode_error2_f_c
                                    }
                                    type="number"
                                    className="form-control-md"
                                    id="input"
                                    name="pincode_c"
                                    placeholder="Enter Pin Code"
                                  />

                                  {pincode_loaded_c === false &&
                                  pincode_error_f_c === true ? (
                                    <div
                                      style={{
                                        color: "#F46E6E",
                                        fontSize: "10.4px",
                                        marginTop: "4px",
                                      }}
                                    >
                                      Enter Pin Code
                                    </div>
                                  ) : null}

                                  {pincode_loaded_c === false &&
                                  pincode_error_f_c === false &&
                                  pincode_error2_f_c === true ? (
                                    <div
                                      style={{
                                        color: "#F46E6E",
                                        fontSize: "10.4px",
                                        marginTop: "4px",
                                      }}
                                    >
                                      Pin Code should be 6 digit
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </Col>
                          </>
                        )}
                        <div>
                          {user.application_use_type === "Client User" &&
                            user.is_superuser && (
                              <span
                                className="link-text"
                                onClick={handleClientAction}
                              >
                                <IconContext.Provider
                                  value={{
                                    className: "icon",
                                  }}
                                >
                                  <MdAdd />
                                </IconContext.Provider>
                                Add New Consignee
                              </span>
                            )}
                        </div>
                      </>
                    </Row>
                  </CardBody>
                ) : null}
              </Card>
            </Col>
          </Row>
        </div>
      )}

      <div className="m-1">
        <Row>
          <Col lg={12} id="pkg_details">
            <Card className="shadow bg-white rounded">
              <CardTitle className="mb-1 header">
                <div className="header-text-icon header-text">
                  Package Details
                  <IconContext.Provider
                    value={{
                      className: "header-add-icon",
                    }}
                  >
                    <div onClick={toggle_circle3}>
                      {circle_btn3 ? (
                        <MdRemoveCircleOutline />
                      ) : (
                        <MdAddCircleOutline />
                      )}
                    </div>
                  </IconContext.Provider>
                </div>
              </CardTitle>
              {circle_btn3 ? (
                <CardBody>
                  <>
                    <Row className="hide">
                      <Col lg={2} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">No. Of Package</Label>
                          <span className="mandatory"> *</span>
                          {row1.map((item1, index1) => (
                            <div key={index1}>
                              <Input
                                min={0}
                                value={item1[4]}
                                style={{ marginBottom: "15px" }}
                                type="number"
                                className="form-control-md"
                                id="input"
                                placeholder={"Enter No. Of Package"}
                                onChange={(event) => {
                                  item1[4] = event.target.value;
                                  setrefresh_row(!refresh_row);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>

                      <Col lg={3} md={6} sm={6}>
                        <div className="mb-2">
                          <Label className="header-child">Items</Label>
                          <span className="mandatory"> *</span>
                          {row1.map((item, index) => (
                            <div className="mb-3">
                              <MultiRowSearchInput
                                data_list={item_list}
                                setdata_list={setitem_list}
                                data_item_s={item[0]}
                                page={item_page}
                                setpage={setitem_page}
                                setsearch_txt={setsearch_item}
                                refresh={refresh_row}
                                setrefresh={setrefresh_row}
                                idx={index}
                                loaded={item_loaded}
                                count={item_count}
                                bottom={item_bottom}
                                setbottom={setitem_bottom}
                                disable_me={row1.length - 1 !== index}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>
                      <Col lg={2} md={6} sm={6}>
                        <div className="mb-2">
                          <Label className="header-child">Packing Mode</Label>
                          <span className="mandatory"> *</span>
                          {row1.map((item, index) => (
                            <div className="mb-3">
                              <MultiRowSearchInput
                                data_list={packingmodeList}
                                setdata_list={setpackingmodeList}
                                data_item_s={item[1]}
                                page={packingmode_page}
                                setpage={setpackingmode_page}
                                setsearch_txt={setpackingmode_search_item}
                                refresh={refresh_row}
                                setrefresh={setrefresh_row}
                                idx={index}
                                loaded={packingmode_loaded}
                                count={packingmode_count}
                                bottom={packingmode_bottom}
                                setbottom={setpackingmode_bottom}
                                disable_me={row1.length - 1 !== index}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>

                      <Col lg={2} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">Actual Weight</Label>
                          <span className="mandatory"> *</span>
                          {row1.map((item1, index1) => (
                            <div key={index1}>
                              <Input
                                min={0}
                                value={item1[2]}
                                type="number"
                                className="form-control-md"
                                id="input"
                                style={{ marginBottom: "15px" }}
                                placeholder={"Actual Weight in Kg "}
                                onChange={(event) => {
                                  item1[2] = event.target.value;
                                  setrefresh_row(!refresh_row);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>
                      <Col lg={2} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">Charge Weight</Label>
                          <span className="mandatory"> *</span>
                          {row1.map((item1, index1) => (
                            <div key={index1}>
                              <Input
                                min={0}
                                value={item1[3]}
                                type="number"
                                className="form-control-md"
                                id="input"
                                style={{ marginBottom: "15px" }}
                                placeholder={"Charge Weight in Kg"}
                                onChange={(event) => {
                                  item1[3] = event.target.value;
                                  setrefresh_row(!refresh_row);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>

                      <Col md={1}>
                        <div className="mb-3" style={{ textAlign: "center" }}>
                          {row1.length > 1 ? (
                            <Label className="header-child">Delete</Label>
                          ) : null}
                          {row1.map((item1, index1) => (
                            <div key={index1}>
                              <IconContext.Provider
                                value={{
                                  className: "icon multi-input",
                                }}
                              >
                                {row1.length > 1 ? (
                                  <>
                                    <div
                                      onClick={() => {
                                        DeletePackage(item1);
                                      }}
                                      style={{
                                        marginTop: "5px",
                                      }}
                                    >
                                      <BiTrash
                                        color="red"
                                        size={18}
                                        style={{
                                          alignItems: "center",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </IconContext.Provider>
                            </div>
                          ))}
                        </div>
                      </Col>
                      <div>
                        {pkg_error && pkg_err_text && (
                          <div style={{ color: "red" }}>{pkg_err_text}</div>
                        )}
                        <span
                          className="link-text"
                          onClick={() => {
                            if (
                              row1[row1.length - 1][0][0] &&
                              row1[row1.length - 1][1][0] &&
                              row1[row1.length - 1][2] &&
                              row1[row1.length - 1][3] &&
                              row1[row1.length - 1][4]
                            ) {
                              const netWeight = parseFloat(
                                row1[row1.length - 1][2]
                              );
                              const chargeWeight = parseFloat(
                                row1[row1.length - 1][3]
                              );

                              // Check if Net Weight is greater than Charge Weight
                              if (netWeight > chargeWeight) {
                                // Set error for invalid weight values
                                setpkg_error(true);
                                setpkg_err_text(
                                  "Net Weight cannot be greater than Charge Weight"
                                );
                              } else {
                                // If all conditions are met, add the package
                                addPackages();
                                setpkg_error(false); // Clear error state
                              }
                            } else {
                              // Set error if fields are empty
                              setpkg_error(true);
                              setpkg_err_text("Package Details are required");
                            }
                          }}
                        >
                          <IconContext.Provider
                            value={{
                              className: "icon",
                            }}
                          >
                            <MdAdd />
                          </IconContext.Provider>
                          Add Another Package
                        </span>
                      </div>
                      <Col lg={4} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">
                            Total No. Of Package
                          </Label>
                          <Input
                            value={total_packages}
                            type="number"
                            className="form-control-md"
                            id="input"
                            style={{ marginBottom: "15px" }}
                            placeholder={"Total Number of Packages"}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">
                            Total Actual Weight
                          </Label>
                          <Input
                            value={total_net_weight}
                            type="number"
                            className="form-control-md"
                            id="input"
                            style={{ marginBottom: "15px" }}
                            placeholder={"Enter Total Actual weight"}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">
                            Total Chargeable Weight
                          </Label>
                          <Input
                            value={total_ch_weight}
                            type="number"
                            className="form-control-md"
                            id="input"
                            style={{ marginBottom: "15px" }}
                            placeholder={"Total Chargeable Weight"}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                </CardBody>
              ) : null}
            </Card>
          </Col>

          <Col lg={12}>
            <Card className="shadow bg-white rounded">
              <CardTitle className="mb-1 header">
                <div className="header-text-icon header-text">
                  Marka
                  <IconContext.Provider
                    value={{
                      className: "header-add-icon",
                    }}
                  >
                    <div onClick={toggle_circle4}>
                      {circle_btn4 ? (
                        <MdRemoveCircleOutline />
                      ) : (
                        <MdAddCircleOutline />
                      )}
                    </div>
                  </IconContext.Provider>
                </div>
              </CardTitle>
              {circle_btn4 ? (
                <CardBody>
                  <>
                    <Row className="hide">
                      <Col lg={4} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child"> Marka</Label>
                          {marka_list.map((item, index) => (
                            <div key={index}>
                              <select
                                style={{
                                  marginBottom: "15px",
                                  boxShadow: "none",
                                }}
                                className="form-select"
                                placeholder="Select status"
                                id="input"
                                value={item.name}
                                onChange={(e) => {
                                  const newArr = [...marka_list];
                                  newArr[index].name = e.target.value;
                                  if (
                                    e.target.value === "OWN MARK" &&
                                    location.state === null
                                  ) {
                                    newArr[index].marking = "";
                                  }
                                  setmarka_list(newArr);
                                }}
                                defaultValue="Select status"
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                {/* <option>OWN MARK</option> */}
                                <option>PRIVATE MARK</option>
                              </select>
                            </div>
                          ))}
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">Marking</Label>
                          {marka_list.map((item, index) => (
                            <div key={index}>
                              <Input
                                value={
                                  item.name === "OWN MARK"
                                    ? `${gr_no_value} / ${total_packages} , ${to_branch}`
                                    : item.marking
                                }
                                style={{ marginBottom: "15px" }}
                                type="text"
                                className="form-control-md"
                                id="input"
                                placeholder={
                                  item.name === "OWN MARK"
                                    ? ""
                                    : "Enter Marking"
                                }
                                onChange={(e) => {
                                  const newArr = [...marka_list];
                                  newArr[index].marking = e.target.value;
                                  setmarka_list(newArr);
                                }}
                                disabled={item.name === "OWN MARK"}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>

                      <Col md={1}>
                        <div className="mb-3" style={{ textAlign: "center" }}>
                          {marka_list.length > 1 ? (
                            <Label className="header-child">Delete</Label>
                          ) : null}
                          {marka_list.map((item, index) => (
                            <div key={index}>
                              <IconContext.Provider
                                value={{
                                  className: "icon multi-input",
                                }}
                              >
                                {marka_list.length > 1 ? (
                                  <>
                                    <div
                                      onClick={() => {
                                        DeleteMarka(index, item);
                                      }}
                                      style={{
                                        marginTop: "5px",
                                      }}
                                    >
                                      <BiTrash
                                        color="red"
                                        size={18}
                                        style={{
                                          alignItems: "center",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </IconContext.Provider>
                            </div>
                          ))}
                        </div>
                      </Col>
                      <div>
                        <span
                          className="link-text"
                          onClick={() => {
                            AddMarka();
                          }}
                        >
                          <IconContext.Provider
                            value={{
                              className: "icon",
                            }}
                          >
                            <MdAdd />
                          </IconContext.Provider>
                          Add Another Marka
                        </span>
                      </div>
                    </Row>
                  </>
                </CardBody>
              ) : null}
            </Card>
          </Col>

          <Col lg={12} id="inv_details">
            <Card className="shadow bg-white rounded">
              <CardTitle className="mb-1 header">
                <div className="header-text-icon header-text">
                  Invoice Details
                  <IconContext.Provider
                    value={{
                      className: "header-add-icon",
                    }}
                  >
                    <div onClick={toggle_circle5}>
                      {circle_btn5 ? (
                        <MdRemoveCircleOutline />
                      ) : (
                        <MdAddCircleOutline />
                      )}
                    </div>
                  </IconContext.Provider>
                </div>
              </CardTitle>
              {circle_btn5 ? (
                <CardBody>
                  <>
                    <Row className="hide">
                      <Col lg={3} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">Invoice Date</Label>
                          <span className="mandatory"> *</span>
                          {invoice_list.map((item, index) => (
                            <div key={index}>
                              <Input
                                max={new Date().toISOString().split("T")[0]}
                                value={item.invoice_at}
                                style={{ marginBottom: "15px" }}
                                type="date"
                                className="form-control-md"
                                id="input"
                                placeholder={"Enter Invoice Date"}
                                onChange={(e) => {
                                  const newArr = [...invoice_list];
                                  newArr[index].invoice_at = e.target.value;
                                  setinvoice_list(newArr);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>
                      <Col lg={3} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">Invoice No</Label>
                          <span className="mandatory"> *</span>
                          {invoice_list.map((item, index) => (
                            <div key={index}>
                              <Input
                                min={0}
                                value={item.invoice_no}
                                style={{ marginBottom: "15px" }}
                                type="char"
                                className="form-control-md"
                                id="input"
                                placeholder={"Enter Invoice Number"}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  if (value.length <= 10) {
                                    const newArr = [...invoice_list];
                                    newArr[index].invoice_no =
                                      e.target.value.toUpperCase();
                                    setinvoice_list(newArr);
                                  }
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>
                      <Col lg={invoice_list.length > 1 ? 2 : 3} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">Invoice Amount</Label>
                          <span className="mandatory"> *</span>
                          {invoice_list.map((item, index) => (
                            <div key={index}>
                              <Input
                                min={0}
                                value={item.invoice_amount}
                                style={{ marginBottom: "15px" }}
                                type="number"
                                className="form-control-md"
                                id="input"
                                placeholder={"Enter Invoice Amount"}
                                onChange={(e) => {
                                  const newArr = [...invoice_list];
                                  newArr[index].invoice_amount = e.target.value;
                                  setinvoice_list(newArr);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>
                      <Col lg={3} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">EWay Bill No.</Label>
                          {invoice_list.map((item, index) => (
                            <div key={index}>
                              <Input
                                min={0}
                                value={item.ewaybill_no}
                                style={{ marginBottom: "15px" }}
                                type="number"
                                className="form-control-md"
                                id="input"
                                placeholder={"Enter Eway Bill No."}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  if (value.length <= 12) {
                                    const newArr = [...invoice_list];
                                    newArr[index].ewaybill_no = e.target.value;
                                    setinvoice_list(newArr);
                                  }
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>

                      <Col md={1}>
                        <div className="mb-3" style={{ textAlign: "center" }}>
                          {invoice_list.length > 1 ? (
                            <Label className="header-child">Delete</Label>
                          ) : null}
                          {invoice_list.map((item, index) => (
                            <div key={index}>
                              <IconContext.Provider
                                value={{
                                  className: "icon multi-input",
                                }}
                              >
                                {invoice_list.length > 1 ? (
                                  <>
                                    <div
                                      onClick={() => {
                                        DeleteInvoice(index, item);
                                      }}
                                      style={{
                                        marginTop: "5px",
                                      }}
                                    >
                                      <BiTrash
                                        color="red"
                                        size={18}
                                        style={{
                                          alignItems: "center",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </IconContext.Provider>
                            </div>
                          ))}
                        </div>
                      </Col>
                      <div>
                        {invoice_error && invoice_err_text && (
                          <div style={{ color: "red" }}>{invoice_err_text}</div>
                        )}
                        <span
                          className="link-text"
                          onClick={() => {
                            if (
                              invoice_list[invoice_list.length - 1]
                                .invoice_at !== "" &&
                              invoice_list[invoice_list.length - 1]
                                .invoice_no !== "" &&
                              invoice_list[invoice_list.length - 1]
                                .invoice_amount !== ""
                            ) {
                              AddInvoice();
                              setinvoice_error(false);
                            } else {
                              setinvoice_error(true);
                              setinvoice_err_text(
                                "Invoice Details are required"
                              );
                            }
                          }}
                        >
                          <IconContext.Provider
                            value={{
                              className: "icon",
                            }}
                          >
                            {/* <button
                              className="hidden-button"
                              type="button"
                            ></button> */}
                            <MdAdd />
                          </IconContext.Provider>
                          Add Another Invoice
                        </span>
                      </div>
                    </Row>
                  </>
                </CardBody>
              ) : null}
            </Card>
          </Col>

          <Col lg={12}>
            <Card className="shadow bg-white rounded">
              <CardTitle className="mb-1 header">
                <div className="header-text-icon header-text">
                  Information
                  <IconContext.Provider
                    value={{
                      className: "header-add-icon",
                    }}
                  >
                    <div onClick={toggle_circle6}>
                      {circle_btn6 ? (
                        <MdRemoveCircleOutline />
                      ) : (
                        <MdAddCircleOutline />
                      )}
                    </div>
                  </IconContext.Provider>
                </div>
              </CardTitle>
              {circle_btn6 ? (
                <CardBody>
                  <>
                    <Row className="hide">
                      <Col lg={3} md={6} sm={6}>
                        <div className="mb-2">
                          <Label className="header-child">Delivery Mode</Label>
                          <NSearchInput
                            data_list={type_of_deliverymode_list}
                            data_item_s={type_of_deliverymode}
                            set_data_item_s={settype_of_deliverymode}
                            show_search={false}
                          />
                          <div className="mt-1 error-text" color="danger">
                            {delivery_mode_error
                              ? "Delivery Mode is required"
                              : null}
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} md={6} sm={6}>
                        <div className="mb-2">
                          <Label className="header-child">
                            Type Of Booking
                          </Label>
                          <NSearchInput
                            data_list={type_of_booking_list}
                            data_item_s={type_of_booking}
                            set_data_item_s={settype_of_booking}
                            error_s={type_of_booking_err}
                            error_message="Select Any Type Of Booking "
                            show_search={false}
                            disable_me

                          />
                        </div>
                      </Col>

                      {/* <Col lg={3} md={6} sm={6}>
                        <div className="mb-3">
                          <Label className="header-child">Pay By</Label>
                          <span className="mandatory"> *</span>
                          <NSearchInput
                            data_list={pay_by_list}
                            data_item_s={pay_by}
                            set_data_item_s={(value) => {
                              setpay_by(value);
                              if (value === "Consignee") {
                                setclient_bill_list(consignee_list);
                              } else if (value === "Consignor") {
                                setclient_bill_list(consignor_list);
                              }
                            }}
                            error_s={pay_by_err}
                            error_message="Select Pay By"
                            show_search={false}
                          />
                        </div>
                      </Col> */}
                    </Row>
                  </>
                </CardBody>
              ) : null}
            </Card>
          </Col>
        </Row>
      </div>

      {/* Footer Btn*/}

      <div className="m-1">
        <Col lg={12}>
          <div className="mb-1 footer_btn">
            {!isLoading ? (
              <button
                type="submit"
                // onClick={onNotify}
                className="btn btn-success m-1"
                style={{ marginRight: "15px", width: "77px" }}
                // disabled={submit_btn == true}
              >
                <BsSave />
                {isupdating ? "Update" : "Save"}
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-success m-1"
                style={{ marginRight: "15px", width: "77px" }}
              >
                <Loader />
              </button>
            )}
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleAction}
            >
              Cancel
            </button>
          </div>
        </Col>
      </div>
    </form>
  );
};
export default AddClientBooking;
