/* eslint-disable */
import React, { useRef, useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { FiSquare, FiCheckSquare } from "react-icons/fi";
import Logo003 from "../../assets/images/Logo003.png";
import toTitleCase from "../../titleCase/TitleCase";


export const ComponentToPrint = React.forwardRef(({ order }, ref) => {
    console.log("order======", order)
    //used for date and time
    const [booking_date, setbooking_date] = useState("");
    const [booking_date_time, setbooking_date_time] = useState("");
    const [invoice_no, setinvoice_no] = useState([])
    const [invoice_value, setinvoice_value] = useState([])
    const [invoice_date, setinvoice_date] = useState([])
    const [eway_bill, seteway_bill] = useState([])
    console.log("=====invoice_value", invoice_value)
    // useLayoutEffect(() => {
    //     if (order.booking_at) {
    //         let s = new Date(order.booking_at).toLocaleString(undefined, {
    //             timeZone: "Asia/Kolkata",
    //         });
    //         let s_date = s.split(",");
    //         setbooking_date(s_date[0]);
    //         setbooking_date_time(s_date[1]);

    //         if (order?.invice_details?.length !== 0) {
    //             let temp = order?.invice_details?.map((v) => v.invoice_no)
    //             let temp2 = order?.invice_details?.map((v) => v.invoice_amount)
    //             let temp3 = order?.invice_details?.map((v) => v.invoice_at.split('T')[0])
    //             // let temp4 = order?.invice_details?.map((v) => v.ewaybill_no)

    //             setinvoice_no(temp)
    //             setinvoice_value(temp2)
    //             setinvoice_date(temp3)
    //             // seteway_bill(temp4)
    //         }

    //     }
    // }, [order.booking_at]);
 

    return (
      <div className="m-2" ref={ref} id="invoice_div">
      <table className="table-grid">
        <tbody>
          <tr>
            <td colSpan={2}>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{display: "flex",}}>
                    <h2 style={{ marginTop: "30px", color: "black", textDecoration: "underline" }}>
                      <b> Aryavart Logs.</b>
                    </h2>
                  </div>
  <div style={{ marginTop: "50px" }}>
    <h3>GR NUMBER</h3>
    <br />
    <h2><b>{order.gr_no}</b></h2>
  </div>
</div>
<img src={order.qrcode} width="150px" height="150px" />
                                       
                                    
                                    
                </div>
              {/* </div> */}
            </td>
            <td colSpan={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 20,
                  marginTop: 20,
                }}
              >
                {/* <img src={Logo003} width="150vw" height="60vh" /> */}
              </div>
              <div style={{ float: "right", marginRight: 20 }}>
                <b>Web:</b>www.avtc.in<br></br>
                <b>E-Mail: </b>info@avtc.in
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <b>
                DATE<br></br>
                {booking_date}
              </b>
            </td>
            <th colSpan={4}>
              &nbsp;<b>ISO 9001 2015 CERTIFIED COMPANY</b>
            </th>
          </tr>
          <tr>
            <th rowSpan={2}>&nbsp;Name</th>
            <td rowSpan={2}>
              Consignor<br></br>
             <b> {order.consignor_name}</b>
            </td>
          </tr>
          <tr>
            <th colSpan={1}>&nbsp;Consignor's Acct No.</th>
            <td colSpan={3}>--</td>
          </tr>
          <tr>
            <th rowSpan={2}>&nbsp;Address</th>
            <td rowSpan={2}>
            {((toTitleCase(order.consignor_address1))  +  ", " + toTitleCase(order.consignor_address2))}
            </td>
            <th colSpan={1}>&nbsp;Consignor,s Ref/Protocol No.</th>
            <td colSpan={3}>--</td>
          </tr>
          <tr>
            <th colSpan={1}>&nbsp;Origin</th>
            <td colSpan={3}>{toTitleCase(order.consignor_city_name)+", " +toTitleCase(order.consignor_state_name )}</td>
          </tr>
          <tr>
            <th rowSpan={2}>&nbsp;Contact No.</th>
            <td rowSpan={2}>
              {order.consignor_mobile}
              <br></br>&nbsp;
            </td>
          </tr>
          <tr>
            <th colSpan={1}>&nbsp;Destination</th>
            <td colSpan={3}>{toTitleCase(order.consignee_city_name)+", " +toTitleCase(order.consignee_state_name )}</td>
          </tr>
          <tr>
            <th rowSpan={2}>&nbsp;Name</th>
            <td rowSpan={2}>
              Consignee<br></br>
             <b>{order.consignee_name}</b>
            </td>
          </tr>
          <tr>
            <th colSpan={1}>&nbsp;No. of Pcs</th>
            <td colSpan={3}>{}</td>
          </tr>
          <tr>
            <th rowSpan={6}>&nbsp;Address</th>
            <td rowSpan={6}>
            {(toTitleCase(order.consignee_address1)) + " ," +(toTitleCase(order.consignee_addres2))}
                                   
            </td>
            <th colSpan={1}>&nbsp;Actual Weight.</th>
            <td colSpan={3}>{}</td>
          </tr>
          <tr>
            <th colSpan={1}>&nbsp;Chargeable Weight</th>
            <td colSpan={3}>{}</td>
          </tr>
          <tr>
            <th colSpan={1}>&nbsp;Invoice Values</th>
            <td colSpan={3}></td>
          </tr>
          <tr>
            <th colSpan={1}>&nbsp;Invoice No.</th>
            <td colSpan={3}></td>
          </tr>
          <tr>
            <th colSpan={1}>&nbsp;Invoice Date</th>
            <td colSpan={3}></td>
          </tr>
          <tr></tr>
          <tr>
            <th rowSpan={2}>&nbsp;Contact No.</th>
            <td rowSpan={2}>
              {order.consignee_mobile}
              <br></br>&nbsp;
            </td>
          </tr>
          <tr>
            <th colSpan={1}>&nbsp;Dimensions</th>
            <td colSpan={3}>-</td>
          </tr>
          <tr>
            <th colSpan={2}>
              &nbsp;Content:: <br></br>&emsp;
            </th>
            <th colSpan={4}>
              &nbsp;Special Instruction :<br></br>&nbsp;<br></br>&nbsp;<br></br>
              <span style={{ paddingLeft: "3px" }}>Remarks : </span>
              <span style={{ fontWeight: "normal" }}></span>
            </th>
          </tr>
          <tr>
            <th rowSpan={2}>
              &nbsp;Consignor's Signature <br></br>&emsp;<br></br>&emsp;<br></br>
              &emsp;
            </th>
            <th rowSpan={2}>
              Date<br></br>Time<br></br>&emsp;<br></br>&emsp;
            </th>
          </tr>
          <tr>
            <th colSpan={2}>
              &nbsp;Consignee Name<br></br>&emsp;<br></br>&emsp;<br></br>&emsp;
            </th>
            <th colSpan={2}>
              Date<br></br>&emsp;<br></br>&emsp;<br></br>&emsp;
            </th>
          </tr>
          <tr>
            <th rowSpan={2}>
              &nbsp;Pick up By <br></br>&emsp;<br></br>&emsp;<br></br>&emsp;
              <br></br>&emsp;
            </th>
            <th rowSpan={2}>
              Date<br></br>Time<br></br>&emsp;<br></br>&emsp;<br></br>&emsp;
            </th>
          </tr>
          <tr>
            <th colSpan={2}>
              &nbsp;Received in good order and condition.<br></br>
              &nbsp;Consignee Signature<br></br>&emsp;<br></br>&emsp;<br></br>
              &emsp;
            </th>
            <th colSpan={2}>
              Date<br></br>&emsp;<br></br>&emsp;<br></br>&emsp;<br></br>&emsp;
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    );
});

const BookingPdf = () => {
    const [order, setorder] = useState([]);
    const location = useLocation();
    console.log("loc=======", location.state)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useLayoutEffect(() => {
        setorder(location.state.order);
    }, []);

    return (
        <div>
            <ComponentToPrint ref={componentRef} order={order} />
            {/* <button
          onClick={() => {
            handlePrint();
            window.location.reload();
          }}
        >
          Reload the Page
        </button> */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                }}
            >
                <button
                    className="btn btn-info m-1"
                    onClick={() => {
                        handlePrint();
                        var style = document.createElement("style");
                        style.innerHTML = `
      @page 
        {
            size:  A4 landscape;   /* auto is the initial value */
            // margin: 5mm;  /* this affects the margin in the printer settings */
            
        }
        @media print {
          body {
            zoom: 98%;
          }
          table {
              page-break-inside: avoid;
          }
        }
        
        
        html
        {
            background-color: #FFFFFF; 
            margin: 0px;  /* this affects the margin on the html before sending to printer */
        }
        
        body
        {   
            /*border: solid 1px black ;*/
            // margin: 5mm 5mm 5mm 5mm; /* margin you want for the content */
        }
      `;
                        document.head.appendChild(style);
                        // window.print();
                        handlePrint();
                    }}
                >
                    Print in A4 Landscape
                </button>

                <button
                    className="btn btn-info m-1"
                    onClick={() => {
                        handlePrint();
                        var style = document.createElement("style");
                        style.innerHTML = `
      @page 
        {
            size:  A5 landscape;   /* auto is the initial value */
            // margin: 5mm;  /* this affects the margin in the printer settings */
            
        }
        @media print {
          body {
            zoom: 68%;
          }
          table {
              page-break-inside: avoid;
          }
        }
        
        html
        {
            background-color: #FFFFFF; 
            margin: 0px;  /* this affects the margin on the html before sending to printer */
        }
        
        body
        {
           
            /*border: solid 1px black ;*/
            // margin: 5mm 5mm 5mm 5mm; /* margin you want for the content */
        }
      `;
                        document.head.appendChild(style);
                        // window.print();
                        handlePrint();
                    }}
                >
                    Print in A5 Landscape
                </button>

                <button
                    className="btn btn-info m-1"
                    onClick={() => {
                        handlePrint();
                        var style = document.createElement("style");
                        style.innerHTML = `
      @page 
        {
            size: A4 portrait;   /* auto is the initial value */
            // margin: 5mm;  /* this affects the margin in the printer settings */
            
        }
        
        html
        {
            background-color: #FFFFFF; 
            margin: 0px;  /* this affects the margin on the html before sending to printer */
        }
        @media print {
          body {
            zoom: 120%;
          }
          table {
              page-break-inside: avoid;
          }
        }
        
        body
        {
           
            /*border: solid 1px black ;*/
            // margin: 5mm 5mm 5mm 5mm; /* margin you want for the content */
        }
      `;
                        document.head.appendChild(style);
                        // window.print();
                        handlePrint();
                    }}
                >
                    Print in A4 Portrait
                </button>

                <button
                    className="btn btn-info m-1"
                    onClick={() => {
                        handlePrint();
                        var style = document.createElement("style");
                        style.innerHTML = `
      @page 
        {
            size: A5 portrait;   /* auto is the initial value */
            // margin: 5mm;  /* this affects the margin in the printer settings */
            
        }
        
        html
        {
            background-color: #FFFFFF; 
            margin: 0px;  /* this affects the margin on the html before sending to printer */
        }
        @media print {
          body {
            zoom: 85%;
          }
          table {
              page-break-inside: avoid;
          }
        }
        
        body
        {
           
            /*border: solid 1px black ;*/
            // margin: 5mm 5mm 5mm 5mm; /* margin you want for the content */
        }3
      `;
                        document.head.appendChild(style);
                        // window.print();
                        handlePrint();
                    }}
                >
                    Print in A5 Portrait
                </button>
            </div>
        </div>
        // window.location.reload();
    );
};

export default BookingPdf;